import { BaseContract } from 'types/contracts/types';
import { RequestWithWeb3Provider } from 'types/requests';
// eslint-disable-next-line import/no-extraneous-dependencies
import { TransactionConfig } from 'web3-core';

export type EstimateGasOptions = {
  contract: BaseContract;
  method: string;
  methodArgs: Array<any>; // TODO: fix any type
  estimateGasArgs: TransactionConfig | [];
} & RequestWithWeb3Provider;

export const estimateGasForMethod = async ({
  contract,
  method,
  methodArgs,
  estimateGasArgs = [],
  web3Provider,
}: EstimateGasOptions): Promise<{ estimatedGas: number; gasPrice: string }> => {
  try {
    const transactionObject = contract.methods[method](...methodArgs);
    const estimatedGas = await transactionObject.estimateGas(estimateGasArgs);

    const gasPrice = await web3Provider.eth.getGasPrice();

    return { estimatedGas, gasPrice };
  } catch (error) {
    console.error('Error estimating gas:', error);
    throw error;
  }
};
