import { createAction } from '@reduxjs/toolkit';
import {
  ApproveReq,
  ClaimReferralTokensReq,
  GetLoginMessageReq,
  RequestWithWeb3Provider,
  SetDadReferralCodeReq,
  UpdateUserDataReq,
} from 'types/requests';

import actionTypes from './actionTypes';

export const approve = createAction<ApproveReq>(actionTypes.APPROVE);
export const getTokenBalance = createAction<RequestWithWeb3Provider>(actionTypes.GET_TOKEN_BALANCE);
export const getNativeBalance = createAction<RequestWithWeb3Provider>(actionTypes.GET_NATIVE_BALANCE);
export const claimReferralTokens = createAction<ClaimReferralTokensReq>(actionTypes.CLAIM_REFERRAL_TOKENS);
export const updateUserData = createAction<UpdateUserDataReq>(actionTypes.UPDATE_USER_DATA);
export const login = createAction<GetLoginMessageReq>(actionTypes.LOGIN);
export const setDadReferralCode = createAction<SetDadReferralCodeReq>(actionTypes.SET_DAD_REFERRAL_CODE);
export const getReferralInfo = createAction(actionTypes.GET_REFERRAL_INFO);
export const getReferralRewardData = createAction(actionTypes.GET_REFERRAL_REWARD_DATA);
export const getReferralTotalEarned = createAction(actionTypes.GET_REFERRAL_TOTAL_EARNED);
