/* PLOP_INJECT_IMPORT_REDUCER */
import airdrop from './airdrop/reducer';
import crowdsale from './crowdsale/reducer';
import modals from './modals/reducer';
import staking from './staking/reducer';
import ui from './ui/reducer';
import user from './user/reducer';

export default {
  /* PLOP_INJECT_PLACE_REDUCER */
  ui,
  user,
  modals,
  airdrop,
  crowdsale,
  staking,
};
