import { ReactNode } from 'react';
import { Trans } from 'react-i18next';
import moment from 'moment';

export const dateFormatter = (date: number | string, format = 'lll', daysLeftFormat = false) => {
  if (typeof date === 'string') {
    return moment(date).format(format);
  }
  if (daysLeftFormat) {
    const differenceDate = moment(date * 1000).diff(moment(), 'days');
    return differenceDate >= 0 ? differenceDate : '0';
  }
  return moment(date * 1000).format(format);
};

type intervalDateObj = {
  datetimeStart?: string | number;
  datetimeEnd: string;
};

const padUnit = (value: string | number) => value?.toString()?.padStart(2, '0');

export const getPayoutTimeLeft = ({ datetimeStart = Date.now(), datetimeEnd }: intervalDateObj): ReactNode => {
  const start = moment(datetimeStart).utc();
  const end = moment(datetimeEnd).utc();
  const diffInDays = end.diff(start, 'days');

  if (diffInDays === 0) {
    const duration = moment.duration(end.diff(start));
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();

    return (
      <Trans
        i18nKey="SignUp.timeLeft"
        values={{ hours: padUnit(hours), minutes: padUnit(minutes) }}
        components={{ strong: <strong /> }}
      />
    );
  }

  return <Trans i18nKey="SignUp.daysLeft" values={{ daysNum: diffInDays + 1 }} components={{ strong: <strong /> }} />;
};

export const isValidPeriod = ({ datetimeStart, datetimeEnd }: intervalDateObj) => {
  return moment.utc(datetimeEnd).valueOf() > Date.now() && Date.now() > moment.utc(datetimeStart).valueOf();
};
