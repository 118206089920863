import { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { Box, Button, Divider, IconButton, Stack, SxProps, Typography } from '@mui/material';
import { CSSProperties } from '@mui/material/styles/createTypography';
import { Copy as CopyIcon, Share as ShareIcon } from 'components/Icon/components';
import { useModal } from 'hooks';
import { ShareSocialModal } from 'modules/landing/components/ShareSocialModal';
import { useShareSocials } from 'modules/landing/hooks';
import { COLOR_BLACK, COLOR_WHITE } from 'theme/colors';
import { BORDER_MAIN, BORDER_RADIUS_DEFAULT } from 'theme/variables';

type Variant = 'input' | 'icon' | 'share';
export interface CopyProps {
  variant?: Variant;
  copyText: string;
  isRefLink?: boolean;
  children?: ReactNode;
  sx?: SxProps;
}

export const COPY_TEXT_SHOW_TIME = 2000;

export const Copy: FC<CopyProps> = ({ variant = 'input', copyText, children, isRefLink, sx }) => {
  const { t } = useTranslation();
  const [helperText, setHelperText] = useState('');
  const [isShareSocialModalOpen, handleOpenShareSocialModal, handleCloseShareSocialModal] = useModal(false);

  const isShareVariant = variant === 'share';
  const isIconVariant = variant === 'icon';
  const isInputVariant = variant === 'input';

  const handleShare = useShareSocials(copyText, isRefLink ? 'link' : 'code');

  useEffect(() => {
    const timer = setTimeout(() => {
      setHelperText('');
    }, COPY_TEXT_SHOW_TIME);
    return () => clearTimeout(timer);
  }, [helperText]);

  const onCopy = useCallback(() => {
    setHelperText(t('header.copied'));
  }, [t]);

  return (
    <>
      {isInputVariant && (
        <CopyToClipboard onCopy={onCopy} text={copyText}>
          <Button
            sx={(theme) => {
              const styles = {
                theme: theme.spacing(2, 2, 2, 3),
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                height: '60px',
                borderRadius: BORDER_RADIUS_DEFAULT,
                background: COLOR_WHITE,
                border: BORDER_MAIN,
                cursor: 'pointer',

                '&:hover': {
                  background: COLOR_WHITE,
                },

                ...(sx as CSSProperties),
              };
              return { ...styles };
            }}
          >
            {children}
            {helperText && (
              <Typography
                className="s"
                sx={{
                  position: 'absolute',
                  bottom: -2,
                  left: '50%',
                  transform: 'translateX(-50%)',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  textTransform: 'none',
                }}
              >
                {helperText}
              </Typography>
            )}
            <CopyIcon />
          </Button>
        </CopyToClipboard>
      )}
      {isIconVariant && (
        <CopyToClipboard onCopy={onCopy} text={copyText}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {children}
            <Button
              variant="text"
              startIcon={<CopyIcon />}
              sx={{
                p: 0,
                height: 'fit-content',
              }}
            />
          </Box>
        </CopyToClipboard>
      )}
      {isShareVariant && (
        <Stack position="relative" width="100%" alignItems="center">
          <Stack
            sx={{
              width: { xs: '270px', sm: 'fit-content' },
              height: '60px',
              borderRadius: BORDER_RADIUS_DEFAULT,
              background: COLOR_WHITE,
              border: BORDER_MAIN,
              cursor: 'pointer',
              ...(sx as CSSProperties),
            }}
            alignItems="center"
            direction="row"
            divider={<Divider sx={{ borderColor: COLOR_BLACK, height: '58px' }} orientation="vertical" />}
          >
            {children}
            <CopyToClipboard onCopy={onCopy} text={copyText}>
              <IconButton sx={{ px: { xs: 1.5, sm: 3 } }}>
                <CopyIcon />
              </IconButton>
            </CopyToClipboard>
            <IconButton sx={{ px: { xs: 1.5, sm: 3 } }} onClick={handleOpenShareSocialModal}>
              <ShareIcon />
            </IconButton>
          </Stack>
          {helperText && (
            <Typography
              className="s"
              sx={{
                position: 'absolute',
                top: '100%',
                left: '50%',
                transform: 'translateX(-50%)',
              }}
            >
              {helperText}
            </Typography>
          )}
        </Stack>
      )}
      <ShareSocialModal
        open={isShareSocialModalOpen}
        onClose={handleCloseShareSocialModal}
        onShareClick={handleShare}
      />
    </>
  );
};
