import { IValidateParams } from './index';

export const validateOnlyNumbers = ({ value, isInteger, decimals }: IValidateParams): boolean => {
  const regexpTemplate =
    decimals === 0 ? '^[0-9]*$' : String.raw`^\d+(\.)?(\d{0,${decimals === undefined ? 18 : decimals}})?$|^$`;
  if ((isInteger && !value.match(/^[+]?[1-9]\d*$/)) || value === '00') {
    return false;
  }
  if (!value.match(regexpTemplate) || value === '00') {
    return false;
  }
  return true;
};
