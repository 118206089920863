/* eslint-disable camelcase */
import { URL } from 'appConstants';
import { LoginReq } from 'types/requests';

import ajax from './ajax';

export const baseApi = {
  getLoginMessage(params: { address: string }) {
    return ajax({
      method: 'get',
      url: URL.getLoginMessage,
      params,
    });
  },
  login(data: LoginReq) {
    return ajax({
      method: 'post',
      url: URL.login,
      data,
    });
  },
  setDadReferralCode(data: { promo_code: string }) {
    return ajax({
      method: 'post',
      url: URL.setDadReferralCode,
      data,
    });
  },
  getReferralInfo() {
    return ajax({
      method: 'get',
      url: URL.getReferralInfo,
    });
  },
  getReferralRewardData() {
    return ajax({
      method: 'get',
      url: URL.getReferralRewardData,
    });
  },
  getReferralTotalEarned() {
    return ajax({
      method: 'get',
      url: URL.getReferralTotalEarned,
    });
  },
};
