/* eslint-disable camelcase */
import { toast } from 'react-toastify';
import apiActions from 'store/apiV2/actions';
import { baseApi } from 'store/apiV2/apiRequestBuilder';
import { all, call, put, takeLatest } from 'typed-redux-saga';
import { validateStatus } from 'utils/validateStatus';

import { login } from '../actions';
import actionTypes from '../actionTypes';
import { disconnectWalletState, updateUserState } from '../reducer';

import { getReferralInfoSaga } from './getReferralInfo';
import { getReferralRewardDataSaga } from './getReferralRewardData';
import { getReferralTotalEarnedSaga } from './getReferralTotalEarned';

export function* loginSaga({
  type,
  payload: { address: userAddress, web3Provider, token, provider },
}: ReturnType<typeof login>) {
  yield put(apiActions.request(type));

  try {
    if (!token) {
      const {
        data: { message },
        status,
      } = yield* call(baseApi.getLoginMessage, {
        address: userAddress,
      });
      if (!validateStatus(status)) return;
      let signature;
      try {
        yield* call(web3Provider.eth.sign, message, userAddress, (error: any, result: any) => {
          signature = result;
        });
      } catch (err: any) {
        signature = yield* call(web3Provider.eth.personal.sign, message, userAddress, '');
      }
      const {
        data: { access_token },
      } = yield call(baseApi.login, {
        message,
        signature: signature as string,
      });
      yield put(
        updateUserState({
          address: userAddress,
          key: access_token,
          provider,
        }),
      );
      toast.success(`Wallet connected: ${userAddress.slice(0, 5)}...${userAddress.slice(-5)}`);
    } else {
      yield put(
        updateUserState({
          address: userAddress,
          key: token,
          provider,
        }),
      );
    }
    yield* all([
      call(getReferralInfoSaga, {
        type: actionTypes.GET_REFERRAL_INFO,
        payload: undefined,
      }),
      call(getReferralRewardDataSaga, {
        type: actionTypes.GET_REFERRAL_REWARD_DATA,
        payload: undefined,
      }),
      call(getReferralTotalEarnedSaga, {
        type: actionTypes.GET_REFERRAL_TOTAL_EARNED,
        payload: undefined,
      }),
    ]);
    yield put(apiActions.success(type));
  } catch (err) {
    console.error(err);
    yield put(apiActions.error(type, err));
    yield put(disconnectWalletState());
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.LOGIN, loginSaga);
}
