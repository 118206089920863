import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Info: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 13 12"
    sx={{
      width: '13px',
      height: '12px',
      color: '#969696',
      ...sx,
    }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.70532 11.9375C9.98451 11.9375 12.6428 9.27919 12.6428 6C12.6428 2.72081 9.98451 0.0625 6.70532 0.0625C3.42613 0.0625 0.767822 2.72081 0.767822 6C0.767822 9.27919 3.42613 11.9375 6.70532 11.9375ZM7.76537 9.04815L7.86019 8.66057C7.81111 8.68362 7.73199 8.70995 7.62349 8.73993C7.51469 8.76991 7.41677 8.78518 7.33072 8.78518C7.14742 8.78518 7.01837 8.75514 6.94344 8.69475C6.86906 8.63435 6.83196 8.52075 6.83196 8.35436C6.83196 8.28843 6.84315 8.19015 6.86657 8.06152C6.88925 7.93205 6.91534 7.81698 6.94435 7.71633L7.2983 6.46321C7.33297 6.3482 7.35675 6.22177 7.36952 6.08377C7.3826 5.94609 7.38868 5.84975 7.38868 5.79508C7.38868 5.53083 7.29605 5.31639 7.11075 5.15097C6.92544 4.98567 6.66156 4.90302 6.31959 4.90302C6.12929 4.90302 5.92811 4.93684 5.71519 5.00441C5.50227 5.07179 5.27969 5.15298 5.04682 5.24779L4.95176 5.63568C5.02116 5.61001 5.10381 5.5824 5.2005 5.55382C5.29678 5.52536 5.39122 5.51064 5.48312 5.51064C5.67074 5.51064 5.79711 5.54263 5.86334 5.60564C5.92957 5.66882 5.96284 5.78121 5.96284 5.94189C5.96284 6.03074 5.95232 6.12945 5.93048 6.23673C5.90895 6.34468 5.88207 6.45883 5.85045 6.57931L5.49498 7.83748C5.46335 7.96969 5.44024 8.08798 5.42571 8.19301C5.41129 8.29792 5.40436 8.40088 5.40436 8.50098C5.40436 8.75958 5.4999 8.97274 5.69093 9.14095C5.88195 9.3085 6.14979 9.39286 6.49413 9.39286C6.71836 9.39286 6.91516 9.36354 7.08454 9.30461C7.25373 9.24586 7.48094 9.16042 7.76537 9.04815ZM7.70231 3.95769C7.86767 3.80437 7.94995 3.61791 7.94995 3.39958C7.94995 3.18174 7.86779 2.99491 7.70231 2.83964C7.53737 2.68481 7.33857 2.60714 7.10612 2.60714C6.87295 2.60714 6.67336 2.68462 6.50684 2.83964C6.34032 2.99491 6.25688 3.18168 6.25688 3.39958C6.25688 3.61791 6.34032 3.80431 6.50684 3.95769C6.67366 4.11156 6.87289 4.18855 7.10612 4.18855C7.33863 4.18855 7.53737 4.11156 7.70231 3.95769Z"
      fill="currentColor"
    />
  </SvgIcon>
);
