import { FC, useRef } from 'react';
import { Button, Typography, useTheme } from '@mui/material';
import { WalletIcon } from 'components/Icon/components';
import { COLOR_WHITE } from 'theme/colors';
import { BORDER_MAIN } from 'theme/variables';
import { shortenPhrase } from 'utils';

export interface AccountProps {
  onClick: () => void;
  width: number;
  address: string;
}

export const Account: FC<AccountProps> = ({ address, width, onClick }) => {
  const popoverRef = useRef(null);
  const theme = useTheme();

  return (
    <Button
      ref={popoverRef}
      onClick={onClick}
      variant="text"
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        background: COLOR_WHITE,
        px: 2,
        py: 2.25,
        borderRadius: '12px',
        border: BORDER_MAIN,
      }}
    >
      {width > theme.breakpoints.values.md && (
        <Typography className="l" mr={2}>
          {shortenPhrase(address, 6, 4)}
        </Typography>
      )}

      <WalletIcon />
    </Button>
  );
};
