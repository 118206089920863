import { FC } from 'react';
import { Grid, GridProps, Link, Stack, Typography } from '@mui/material';
import { COLOR_BLACK, COLOR_LIGHT_GREEN } from 'theme/colors';

import { linksData } from './SocialLink.helper';

export const SocialLinks: FC<GridProps> = ({ ...gridProps }) => {
  return (
    <Grid {...gridProps} item container alignItems="center" spacing={{ xs: '13px' }}>
      {linksData.map(({ href, icon, label, xs, md }, index) => (
        <Grid
          item
          key={href}
          xs={xs}
          {...(md && { md })}
          component={Link}
          href={href}
          sx={{
            textDecoration: 'none',
          }}
        >
          <Stack
            sx={{
              border: `1px solid ${COLOR_BLACK}`,
              borderRadius: '13px',
              height: '95px',
              transition: 'all 0.3s ease',
              ...(index === 0 && {
                background: COLOR_LIGHT_GREEN,
                height: '91px',
              }),
              '&:hover': {
                background: COLOR_LIGHT_GREEN,
                ...(index === 0 && {
                  background: 'transparent',
                }),
              },
            }}
            justifyContent="center"
            alignItems="center"
            spacing={{ xs: 0.5, sm: '13px' }}
          >
            {icon}
            <Typography sx={{ textAlign: 'center', lineHeight: '20px' }}>{label}</Typography>
          </Stack>
        </Grid>
      ))}
    </Grid>
  );
};
