import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const WarningIcon: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 25 24"
    sx={{
      ...sx,
      width: '25px',
      height: '24px',
    }}
  >
    <path
      d="M12.5 9V11M12.5 15H12.51M5.57183 19H19.4282C20.9678 19 21.9301 17.3333 21.1603 16L14.2321 4C13.4623 2.66667 11.5378 2.66667 10.768 4L3.83978 16C3.06998 17.3333 4.03223 19 5.57183 19Z"
      stroke="#DE3737"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="#fff"
    />
  </SvgIcon>
);
