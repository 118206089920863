import { SevlushIcon, Support, TelegramIcon, TwitterIcon, YouTube } from 'components/Icon/components';

export const linksData = [
  {
    href: 'https://t.me/AGTI_HELP',
    label: 'AGTI HELP',
    icon: <Support />,
    xs: 12,
  },
  {
    href: 'https://t.me/AGTInwCORE',
    label: 'AGTInw CORE',
    icon: <TelegramIcon />,
    xs: 6,
    md: 4,
  },
  {
    href: 'https://www.youtube.com/@agroglorytime',
    label: 'YouTube',
    icon: <YouTube />,
    xs: 6,
    md: 4,
  },
  {
    href: 'https://sevlushfoods.com/en/',
    label: 'AgroGlory Time',
    icon: <SevlushIcon />,
    xs: 6,
    md: 4,
  },
  {
    href: 'https://agroglorytime.io/',
    label: 'AGTI',
    icon: <SevlushIcon />,
    xs: 6,
    md: 3,
  },
  {
    href: 'https://t.me/AgroGloryTime',
    label: 'News & Updates',
    icon: <TelegramIcon />,
    xs: 4,
    md: 3,
  },
  {
    href: 'https://t.me/+vmLgfXuOoAJlYzIy',
    label: 'English chat',
    icon: <TelegramIcon />,
    xs: 4,
    md: 3,
  },
  {
    href: 'https://x.com/agro_glory_time?s=21&t=f92_Bw6mH6LePNlREMjHdg',
    label: 'Twitter',
    icon: <TwitterIcon />,
    xs: 4,
    md: 3,
  },
];
