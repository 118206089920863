import { FC, ReactNode, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Container, useTheme } from '@mui/material';
import { useSmoothTopScroll, useWindowState } from 'hooks';
import useAnchorLink from 'modules/layout/hooks/useAnchorLink/useAnchorLink';
import { useCheckPrivateRoute } from 'modules/layout/hooks/useCheckPrivateRoute';
import { COLOR_BEIGE } from 'theme/colors';
import { GET_REWARDS_IMAGE_BG, HOME_IMAGE_BG } from 'theme/variables';

import { Footer, Header, NotificationModal } from '..';

export interface LayoutProps {
  children?: ReactNode;
}

export const Layout: FC<LayoutProps> = ({ children }) => {
  const { pathname } = useLocation();
  const { width } = useWindowState();

  const firstPathAtPathname = useMemo(() => pathname.split('/')[1], [pathname]);

  const theme = useTheme();

  const BG = useMemo(() => {
    if (+width > theme.breakpoints.values.md) {
      switch (pathname) {
        case '/':
          return HOME_IMAGE_BG;
        case '/get-rewards':
          return GET_REWARDS_IMAGE_BG;
        default:
          return COLOR_BEIGE;
      }
    }

    return COLOR_BEIGE;
  }, [pathname, theme.breakpoints.values.md, width]);

  useSmoothTopScroll(firstPathAtPathname);
  useAnchorLink();
  useCheckPrivateRoute(pathname);

  return (
    <Box
      sx={{
        height: '100%',
        background: BG,
      }}
    >
      <Header />
      <NotificationModal />
      <Container
        sx={{
          pt: 15,
          px: 3,
        }}
      >
        {children}
      </Container>
      <Footer />
    </Box>
  );
};
