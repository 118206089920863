export const formatNumber = (number: number | string) => new Intl.NumberFormat().format(+number);

export const formatCurrency = (amount: number | string, locale = 'en-US', currency = 'usd'): string =>
  new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  }).format(+amount);

export const formatToken = (
  amount: number | string,
  minimumFractionDigits = 2,
  maximumFractionDigits = 2,
  locale = 'fr',
): string =>
  new Intl.NumberFormat(locale, {
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(+amount);

export const formatTokenForDisplay = (amount: string | number, minimumFractionDigits = 0) => {
  return +amount > 0.001 || +amount === 0 ? formatToken(amount, minimumFractionDigits, 3) : '>0.001';
};
