import { GAS_PRICE_MULTIPLIER } from 'appConstants';
import { contractsConfig, ContractsNames } from 'services/WalletService/config';
import apiActions from 'store/api/actions';
import store from 'store/configureStore';
import { setActiveModal } from 'store/modals/reducer';
import userSelector from 'store/user/selectors';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import { Modals, UserState } from 'types';
import { CrowdsaleAbi } from 'types/contracts/CrowdsaleAbi';
import { estimateGasForMethod } from 'utils';
import { AbiItem } from 'web3-utils';

import { claimTokens } from '../actions';
import actionTypes from '../actionTypes';

import { getClaimDataSaga } from './getClaimData';

export function* claimTokensSaga({
  type,
  payload: { web3Provider, vestingPeriods },
}: ReturnType<typeof claimTokens>): Generator<unknown, void, any> {
  yield* put(apiActions.request(type));
  const { address: userAddress, network, chainType }: UserState = yield select(userSelector.getUser);

  const { address: crowdsaleContractAddress, abi: crowdsaleAbi } =
    contractsConfig.contracts[ContractsNames.crowdsale][chainType];

  const crowdsaleContract: CrowdsaleAbi = yield new web3Provider.eth.Contract(
    crowdsaleAbi as AbiItem[],
    crowdsaleContractAddress[network],
  );

  try {
    yield* put(
      setActiveModal({
        activeModal: Modals.SendPending,
        open: true,
      }),
    );

    const { estimatedGas, gasPrice } = yield* call(estimateGasForMethod, {
      web3Provider,
      contract: crowdsaleContract,
      method: 'claim',
      estimateGasArgs: { from: userAddress },
      methodArgs: [vestingPeriods],
    });

    const { transactionHash } = yield call(crowdsaleContract.methods.claim(vestingPeriods).send, {
      from: userAddress,
      gasPrice: Number(gasPrice) * GAS_PRICE_MULTIPLIER,
      gas: estimatedGas,
    });

    yield* put(
      setActiveModal({
        activeModal: Modals.SendSuccess,
        open: true,
        txHash: transactionHash,
      }),
    );

    yield call(getClaimDataSaga, {
      type: actionTypes.GET_CLAIM_DATA,
      payload: {
        web3Provider,
      },
    });

    yield* put(apiActions.success(type));
  } catch (err) {
    console.error(err);
    yield* put(apiActions.error(type, err));

    yield* put(
      setActiveModal({
        activeModal: Modals.SendRejected,
        open: true,
        repeatHandler: () =>
          store.store.dispatch({
            type,
            payload: {
              web3Provider,
              vestingPeriods,
            },
          }),
      }),
    );
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.CLAIM_TOKENS, claimTokensSaga);
}
