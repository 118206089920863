import { ConnectWallet } from '@amfi/connect-wallet';
import { IConnect, IConnectorMessage, IError } from '@amfi/connect-wallet/dist/interface';
import { isProduction } from 'appConstants';
import { chains, connectWallet as connectWalletConfig } from 'services/WalletService/config';
import { Chains, IChainType, WalletProviders } from 'types';
import { getToastMessage, getWeb3 } from 'utils';
import Web3 from 'web3';

import { notifyText } from './config/constants';

export class WalletService {
  public connectWallet: ConnectWallet;

  constructor(initProvider?: string) {
    this.connectWallet = new ConnectWallet(initProvider);
  }

  public async initWalletConnect(
    providerName: WalletProviders,
    chainName: Chains,
    type = 'testnet',
  ): Promise<boolean | IConnectorMessage> {
    const { provider, network, settings } = connectWalletConfig(chainName, type as IChainType);

    try {
      const connecting = await this.connectWallet.connect(provider[providerName], network, settings);

      return connecting;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      // eslint-disable-next-line no-console
      console.error('initWalletConnect providerWallet err: ', error);
      if (error.code === 2) {
        if (error.type === 'MetaMask') {
          getToastMessage('info', notifyText.wallet.connect.info.noExtension(providerName));
        } else if (error.type === 'CoinbaseWallet') {
          getToastMessage('info', notifyText.wallet.connect.info.noExtension(providerName));
        }
      } else if (error.code === 4) {
        if (error.type === 'MetaMask') {
          // Add unknown chain for MetaMask
          const { network: networkConfig } = chains[chainName][type as IChainType];
          if (networkConfig) {
            // @see https://docs.metamask.io/guide/rpc-api.html#wallet-addethereumchain
            window.ethereum?.request({
              method: 'wallet_addEthereumChain',
              params: [networkConfig],
            });
          }
        }
      }

      return false;
    }
  }

  public resetConnect(): void {
    this.connectWallet.resetConect();
  }

  public eventSubscribe(): any {
    return this.connectWallet.eventSubscriber();
  }

  public Web3(): Web3 {
    if (!this.connectWallet.currentWeb3()) {
      return getWeb3(
        isProduction ? 'https://bsc-dataseed1.binance.org/' : 'https://data-seed-prebsc-1-s1.binance.org:8545/',
      );
    }
    return this.connectWallet.currentWeb3();
  }

  public getAccount(): Promise<IConnect | IError | { address: string }> {
    return this.connectWallet.getAccounts();
  }

  sendTransaction(transactionConfig: any, walletAddress: string): any {
    return this.Web3().eth.sendTransaction({
      ...transactionConfig,
      from: walletAddress,
    });
  }
}
