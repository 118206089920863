import { FC, lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { routes } from 'appConstants/routes';

const Home = lazy(() => import('modules/landing/pages/Home').then((module) => ({ default: module.Home })));
const GetRewards = lazy(() =>
  import('modules/getRewards/pages/GetRewards').then((module) => ({ default: module.GetRewards })),
);
const Staking = lazy(() => import('modules/staking/pages/Staking').then((module) => ({ default: module.Staking })));

export const RouteManager: FC = () => {
  return (
    <Routes>
      <Route path="*" element={<Navigate to="/" />} />
      <Route path={routes.home.root} element={<Home />} />
      <Route path={routes.getRewards.root} element={<GetRewards />} />
      <Route path={routes.staking.root} element={<Staking />} />
    </Routes>
  );
};
