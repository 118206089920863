import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material';
import { COLOR_BLACK, COLOR_GRAY_LIGHT, COLOR_GRAY_MEDIUM, COLOR_WHITE } from 'theme/colors';
import { FontWeights } from 'theme/Typography';
import { BORDER_MAIN_BOLD, BORDER_RADIUS_MEDIUM } from 'theme/variables';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getMuiTextFieldOverrides = (theme?: Theme): ComponentsOverrides['MuiTextField'] => ({
  root: {
    boxShadow: 'none',
    borderColor: 'transparent',
    borderRadius: BORDER_RADIUS_MEDIUM,
    fontSize: '16px',
    '& input::placeholder': {
      opacity: 1,
      color: COLOR_GRAY_LIGHT,
      fontWeight: FontWeights.fontWeightRegular,
    },
    '.MuiInputBase-root': {
      '&.MuiInputBase-sizeSmall': {
        height: '48px !important',
        '.MuiInputBase-input': { padding: 0, paddingTop: 6 },
        '& .MuiInputBase-input': {
          fontSize: '24px',
          lineHeight: '32px',
        },
      },
      '&.MuiInputBase-sizeMedium': {
        height: '60px',
      },
    },
    '& .MuiInputBase-input': {
      fontSize: '23px',
    },
    '& .MuiOutlinedInput-input': {
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
      },
    },
    '& .MuiOutlinedInput-root': {
      border: '1px solid',
      overflow: 'hidden',
      color: COLOR_BLACK,
      borderRadius: BORDER_RADIUS_MEDIUM,

      '& .Mui-disabled': {
        background: COLOR_GRAY_MEDIUM,
      },

      '& fieldset': {
        border: '1px solid',
        borderColor: COLOR_BLACK,

        '&.MuiOutlinedInput-notchedOutline': {
          borderColor: COLOR_BLACK,
        },
      },
      '&.Mui-focused fieldset': {
        border: `1px solid ${COLOR_BLACK}`,
      },
    },

    '& .MuiFilledInput-root': {
      overflow: 'hidden',
      color: COLOR_BLACK,
      borderRadius: BORDER_RADIUS_MEDIUM,
      background: COLOR_WHITE,
      border: BORDER_MAIN_BOLD,

      '&:before': {
        border: 'none',
      },

      '&:hover, &:active': {
        background: COLOR_WHITE,
        '&:before': {
          border: 'none !important',
        },
      },

      '&.Mui-focused': {
        background: COLOR_WHITE,
      },

      '&.Mui-disabled': {
        background: COLOR_GRAY_MEDIUM,
        '&:before': {
          border: 'none !important',
        },
      },

      '& fieldset': {
        '&.MuiFilledInput-notchedOutline': {
          borderColor: COLOR_BLACK,
        },
      },
      '&.Mui-focused fieldset': {
        border: `1px solid ${COLOR_BLACK}`,
      },
    },
    '& .MuiInput-underline:after': {
      borderBottom: 'none',
    },
    '& .MuiFilledInput-underline:after': {
      borderBottom: 'none',
    },
    '& .MuiFormHelperText-root': {
      fontSize: '16px',
      marginLeft: 0,
    },
    'input:-webkit-autofill': {
      boxShadow: '0 0 0 1000px transparent inset !important',
      transitionDelay: '999999s',
      transitionProperty: 'background-color, color',
    },
    'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    'input[type=number]': {
      MozAppearance: 'textfield',
    },
  },
});

export const getMuiTextFieldDefaultProps = (): ComponentsProps['MuiTextField'] => ({
  InputProps: {
    autoComplete: 'off',
    disableUnderline: true,
    style: {
      height: '60px',
      padding: '0 14px',
    },
  },
});
