import { errorStatuses } from 'appConstants';
import { error, request } from 'store/api/actions';
import { baseApi } from 'store/apiV2/apiRequestBuilder';
import { call, put, takeLatest } from 'typed-redux-saga';
import { ReferralInfoModel } from 'types';
import { camelize, validateStatus } from 'utils';

import { getReferralInfo } from '../actions';
import actionTypes from '../actionTypes';
import { updateUserState } from '../reducer';

export function* getReferralInfoSaga({ type }: ReturnType<typeof getReferralInfo>) {
  yield put(request(type));
  try {
    const { data, status: resStatus } = yield call(baseApi.getReferralInfo);

    // *Hotfix* If status === 403 -> reject and disconnect
    if (errorStatuses.includes(resStatus)) {
      throw new Error();
    }

    if (!validateStatus(resStatus)) return;
    const camelizedData = camelize(data) as ReferralInfoModel;
    yield put(
      updateUserState({
        referralInfo: {
          ...camelizedData,
        },
      }),
    );
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    yield put(error(type));

    // *Hotfix* Throw error to disconnect user
    throw err;
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_REFERRAL_INFO, getReferralInfoSaga);
}
