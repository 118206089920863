import { ComponentsOverrides, ComponentsProps, switchClasses, Theme } from '@mui/material';
import { COLOR_GRAY_LIGHT_2, COLOR_LIGHT_GREEN, COLOR_WHITE } from 'theme/colors';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getMuiSwitchOverrides = (theme?: Theme): ComponentsOverrides['MuiSwitch'] => ({
  root: {
    padding: 0,
    width: 40,
    height: 20,
    [`.${switchClasses.switchBase}.${switchClasses.checked} + .${switchClasses.track}`]: {
      background: `${COLOR_LIGHT_GREEN}`,
      opacity: 1,
    },
  },
  switchBase: {
    borderRadius: 4,
    top: '50%',
    color: COLOR_WHITE,
    padding: 0,
    left: 3,
    transform: 'translateY(-50%)',
    transition: '0.1s ease-out',
    [`&.${switchClasses.checked}`]: {
      left: 'calc(100% - 18px - 3px)',
      transform: 'translateY(-50%)',
      color: COLOR_WHITE,
    },
  },
  track: {
    borderRadius: 6,
    background: COLOR_GRAY_LIGHT_2,
    border: '0.5px solid #E4E4E7',
    width: 40,
    height: 20,
    opacity: 1,
  },
  thumb: {
    borderRadius: 4,
    width: 18,
    height: 15,
    boxShadow: 'initial',
  },
});

export const getMuiSwitchDefaultProps = (): ComponentsProps['MuiSwitch'] => ({
  disableRipple: true,
});
