import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const TwitterIcon: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 30 25"
    sx={{
      ...sx,
      width: '30px',
      height: '25px',
    }}
  >
    <path
      d="M9.4377 24.1309C20.7557 24.1309 26.9479 14.8964 26.9479 6.89073C26.9479 6.63112 26.942 6.36574 26.9303 6.10614C28.1348 5.24845 29.1744 4.18608 30 2.96895C28.8782 3.46038 27.6871 3.78133 26.4674 3.92083C27.7516 3.16292 28.7132 1.97227 29.1738 0.569611C27.9657 1.27456 26.6445 1.77184 25.2668 2.04013C24.3385 1.06903 23.1112 0.426047 21.7746 0.21059C20.438 -0.0048672 19.0665 0.219204 17.8722 0.848161C16.6779 1.47712 15.7273 2.47593 15.1673 3.69017C14.6074 4.90441 14.4693 6.26645 14.7744 7.5657C12.3281 7.44483 9.93492 6.81915 7.74998 5.72921C5.56503 4.63928 3.63712 3.10942 2.09121 1.23882C1.3055 2.57259 1.06507 4.15087 1.41879 5.6529C1.77251 7.15493 2.69383 8.468 3.99551 9.32525C3.01829 9.2947 2.06247 9.03566 1.20703 8.56951V8.64451C1.20616 10.0442 1.69762 11.401 2.59789 12.4843C3.49815 13.5675 4.75165 14.3104 6.14531 14.5866C5.24007 14.8305 4.28998 14.866 3.36855 14.6904C3.76182 15.8942 4.52697 16.947 5.55721 17.702C6.58746 18.457 7.8314 18.8764 9.11543 18.9018C6.93553 20.5878 4.24272 21.5022 1.4707 21.4979C0.979109 21.4971 0.487999 21.4675 0 21.409C2.81607 23.1878 6.09191 24.1326 9.4377 24.1309Z"
      fill="#323232"
    />
  </SvgIcon>
);
