import { ComponentsOverrides, ComponentsProps } from '@mui/material';
import { COLOR_BEIGE } from 'theme/colors';
import { BORDER_RADIUS_DEFAULT } from 'theme/variables';

export const getMuiDialogOverrides = (): ComponentsOverrides['MuiDialog'] => ({
  root: {},

  paper: {
    background: COLOR_BEIGE,
    borderRadius: BORDER_RADIUS_DEFAULT,
  },
});

export const getMuiDialogDefaultProps = (): ComponentsProps['MuiDialog'] => ({});
