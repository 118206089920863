const GET_REGISTRATION_DATES = 'GET_REGISTRATION_DATES' as const;
const GET_REGISTRATION_STATUS = 'GET_REGISTRATION_STATUS' as const;
const SIGN_UP = 'SIGN_UP' as const;
const GET_AIRDROP_DATA = 'GET_AIRDROP_DATA' as const;
const CLAIM_REWARD = 'CLAIM_REWARD' as const;
const UPDATE_AIRDROP_DATA = 'UPDATE_AIRDROP_DATA' as const;

export default {
  GET_REGISTRATION_DATES,
  GET_REGISTRATION_STATUS,
  SIGN_UP,
  GET_AIRDROP_DATA,
  CLAIM_REWARD,
  UPDATE_AIRDROP_DATA,
};
