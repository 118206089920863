import { Chains, IChainType, WalletProviders } from 'types';
import { shortenPhrase } from 'utils';

export const notifyText = {
  wallet: {
    connect: {
      success: (userWalletAddress: string, isShortenPhrase = true) =>
        `Wallet connected: ${isShortenPhrase ? shortenPhrase(userWalletAddress, 3, 3) : userWalletAddress}`,
      info: {
        noExtension: (provider: WalletProviders) => `${provider} extension isn't installed or unlocked`,
        pleaseLogin: 'Please sign login message at MetaMask',
      },
      error: {
        wrongNetwork: (network: Chains, chainType: IChainType) =>
          `You changed to wrong network. Please choose ${network} ${chainType}`,
        defaultError: 'Something went wrong',
      },
    },
  },
  disconnet: {
    info: 'Disconnect from wallet ',
  },
  approve: {
    success: 'Successful approve',
    error: 'Error approve',
  },
  registration: {
    success: 'Wallet address successfully added',
    error: 'This wallet address is already registered',
  },
  claim: {
    info: 'This reward has already been claimed',
  },
};
