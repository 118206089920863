const GET_STAKING_DATA = 'GET_STAKING_DATA' as const;
const GET_ONE_STAKE_DATA = 'GET_ONE_STAKE_DATA' as const;
const STAKE = 'STAKE' as const;
const CLAIM = 'CLAIM' as const;
const UNSTAKE = 'UNSTAKE' as const;

export default {
  GET_STAKING_DATA,
  GET_ONE_STAKE_DATA,
  STAKE,
  CLAIM,
  UNSTAKE,
};
