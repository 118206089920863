import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const AgtiToken: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0  100 100"
    sx={{
      width: '36px',
      height: '37px',
      ...sx,
    }}
  >
    <circle cx="50" cy="50" r="50" fill="#15162B" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M46.2311 22.1735C44.4389 22.4727 41.5968 23.5964 39.9662 24.6507C32.5425 29.4497 29.8555 39.9395 33.84 48.5677L34.7902 50.6257L34.1382 52.5057C32.713 56.6171 32.6978 61.9775 34.1001 65.9605C37.3261 75.1224 47.1235 80.1654 57.1319 77.8154C58.8261 77.4177 62.4827 75.7611 63.4792 74.9399L64.2247 74.3257L63.405 73.0895L62.5856 71.8536L61.237 72.7622C58.073 74.8936 52.7453 75.7197 48.5333 74.7316C40.6061 72.8719 35.6925 65.3493 36.8613 56.8619C37.2526 54.0226 37.4936 53.6615 38.4301 54.5138C41.7785 57.5607 48.143 59.0133 52.8927 57.8143C57.0938 56.754 60.3552 54.5398 62.5514 51.2571L63.7751 49.4279L63.8601 53.3024L63.9449 57.1766H65.6103H67.2757L67.3538 40.1752L67.4323 23.1739H65.6221H63.8123L63.7272 26.8764L63.6421 30.5789L62.8203 29.1835C61.8041 27.4579 59.2642 25.1107 57.2103 23.9984C54.2905 22.4174 49.5202 21.6246 46.2311 22.1735ZM54.642 25.9195C60.7501 28.005 64.6404 34.5816 63.9398 41.6369L63.7421 43.6278L61.1183 42.3006C53.7366 38.5667 44.6542 39.786 38.861 45.2886C37.5877 46.4979 36.9507 46.9217 36.7905 46.6657C36.6633 46.4623 36.3508 45.3848 36.0961 44.2716C33.6535 33.5914 40.7326 24.4343 50.857 25.1772C51.9858 25.26 53.6891 25.594 54.642 25.9195ZM56.2844 43.7584C57.9071 44.0963 61.0205 45.4537 62.1227 46.3042C62.718 46.7633 62.7165 46.7796 61.9752 48.1938C59.4556 52.9995 53.4205 55.8688 47.6934 54.9838C45.0835 54.5806 41.6652 52.9748 40.2021 51.4641L39.1693 50.3978L40.0117 49.1843C41.6362 46.8446 44.7765 44.7896 48.0843 43.9016C50.0555 43.3724 54.0909 43.302 56.2844 43.7584Z"
      fill="white"
    />
  </SvgIcon>
);
