export default {
  // api v1
  getRegistrationDates: '/registraton-dates',
  getRegistrationStatus: `/registration/`,
  signUp: '/registration/',
  getAirdropData: '/airdrop/',
  // api v2
  getLoginMessage: '/auth/message_to_sign',
  login: '/auth/login',
  setDadReferralCode: '/users/set_dad',
  getReferralInfo: '/users/me',
  getReferralRewardData: '/users/rewards',
  getReferralTotalEarned: '/users/rewards/total_earned',
};
