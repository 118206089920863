import { getRegistrationDates as getRegDates } from 'store/airdrop/actions';
import actionTypes from 'store/airdrop/actionTypes';
import { error, request, success } from 'store/api/actions';
import { baseApi } from 'store/api/apiRequestBuilder';
import userSelector from 'store/user/selectors';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import { UserState } from 'types';

import { updateAirdropState } from '../reducer';

export function* getRegistrationStatus({ type }: ReturnType<typeof getRegDates>) {
  yield put(request(type));
  try {
    const userAddress: UserState['address'] = yield select(userSelector.getProp('address'));

    const {
      data: { address },
    } = yield call(baseApi.getRegistrationStatus, { address: userAddress });

    yield put(
      updateAirdropState({
        isRegistered: address,
      }),
    );

    yield put(success(type));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    yield put(error(type));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_REGISTRATION_STATUS, getRegistrationStatus);
}
