const GET_BUY_DATA = 'GET_BUY_DATA' as const;
const GET_CLAIM_DATA = 'GET_CLAIM_DATA' as const;
const BUY_TOKENS = 'BUY_TOKENS' as const;
const CLAIM_TOKENS = 'CLAIM_TOKENS' as const;

export default {
  GET_BUY_DATA,
  GET_CLAIM_DATA,
  BUY_TOKENS,
  CLAIM_TOKENS,
};
