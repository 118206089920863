import { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, StackProps, Typography } from '@mui/material';
import { Copy } from 'components/Copy';
import { FontWeights } from 'theme/Typography';
import { BORDER_MAIN, BORDER_RADIUS_DEFAULT } from 'theme/variables';

type ClaimBlockProps = {
  copyText: string;
  isReferral?: boolean;
  shareText?: string;
  refLink?: string;
};

export const ClaimBlockWrapper: FC<PropsWithChildren<ClaimBlockProps & StackProps>> = ({
  children,
  copyText,
  isReferral = false,
  refLink,
  ...stackProps
}) => {
  const { t } = useTranslation();

  return (
    <Stack
      {...stackProps}
      spacing={3}
      sx={{
        width: '100%',
        px: { xs: 1.75, md: 3 },
        py: 3,
        border: BORDER_MAIN,
        borderRadius: BORDER_RADIUS_DEFAULT,
        ...stackProps.sx,
      }}
    >
      <Stack spacing={isReferral ? 3 : 1} alignItems={isReferral ? 'center' : 'flex-start'}>
        {isReferral ? (
          <>
            <Typography variant="body1" className="s" align="center">
              {t('Referral.claim.title')}
            </Typography>
            <Copy copyText={copyText} variant="share">
              <Typography variant="body1" fontWeight={FontWeights.fontWeightBold} className="l" noWrap sx={{ px: 3 }}>
                {copyText}
              </Typography>
            </Copy>
            {refLink && (
              <Stack spacing={1} width="100%">
                <Typography variant="body1" className="s" align="center">
                  {t('Referral.claim.refLink')}
                </Typography>
                <Copy
                  copyText={refLink}
                  isRefLink
                  variant="share"
                  sx={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}
                >
                  <Typography variant="body1" className="xs" noWrap sx={{ px: 3 }}>
                    {refLink}
                  </Typography>
                </Copy>
              </Stack>
            )}
          </>
        ) : (
          <>
            <Typography variant="body1">{t('Crowdsale.claim.walletAddress')}</Typography>
            <Copy copyText={copyText}>
              <Typography className="l" noWrap>
                {copyText}
              </Typography>
            </Copy>
          </>
        )}
      </Stack>
      {children}
    </Stack>
  );
};
