import { MainLogo } from 'components/Icon/components';

export const routes = {
  crowdsale: {
    root: '/#crowdsale',
    title: 'crowdsale',
  },
  staking: {
    root: '/staking',
    title: 'staking',
  },
  roadmap: {
    root: '/#roadmap',
    title: 'roadmap',
  },
  getRewards: {
    root: '/get-rewards',
    title: 'getRewards',
    isProtected: true,
  },
  home: {
    root: '/',
    title: <MainLogo />,
  },
  team: {
    root: '/#team',
    title: 'team',
  },
  aboutUs: {
    root: '/#aboutUs',
    title: 'aboutUs',
  },
};
