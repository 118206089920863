import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Share: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 25 25"
    sx={{
      ...sx,
      width: '25px',
      height: '25px',
    }}
  >
    <path
      d="M9.18387 13.8419C9.38616 13.4381 9.5 12.9824 9.5 12.5C9.5 12.0176 9.38616 11.5619 9.18387 11.1581M9.18387 13.8419C8.69134 14.8251 7.67449 15.5 6.5 15.5C4.84315 15.5 3.5 14.1569 3.5 12.5C3.5 10.8431 4.84315 9.5 6.5 9.5C7.67449 9.5 8.69134 10.1749 9.18387 11.1581M9.18387 13.8419L15.8161 17.1581M9.18387 11.1581L15.8161 7.84193M15.8161 7.84193C16.3087 8.82508 17.3255 9.5 18.5 9.5C20.1569 9.5 21.5 8.15685 21.5 6.5C21.5 4.84315 20.1569 3.5 18.5 3.5C16.8431 3.5 15.5 4.84315 15.5 6.5C15.5 6.98237 15.6138 7.43815 15.8161 7.84193ZM15.8161 17.1581C15.6138 17.5619 15.5 18.0176 15.5 18.5C15.5 20.1569 16.8431 21.5 18.5 21.5C20.1569 21.5 21.5 20.1569 21.5 18.5C21.5 16.8431 20.1569 15.5 18.5 15.5C17.3255 15.5 16.3087 16.1749 15.8161 17.1581Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
