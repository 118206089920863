import crowdsaleActionTypes from 'store/crowdsale/actionTypes';
import stakingActionTypes from 'store/staking/actionTypes';
import userActionTypes from 'store/user/actionTypes';
import { UIState } from 'types';
import { RequestStatus } from 'types/store';

import { getUIReducer } from '.';

const initialState: UIState = {
  [userActionTypes.APPROVE]: RequestStatus.INIT,
  [userActionTypes.SET_DAD_REFERRAL_CODE]: RequestStatus.INIT,
  [crowdsaleActionTypes.GET_BUY_DATA]: RequestStatus.INIT,
  [stakingActionTypes.STAKE]: RequestStatus.INIT,
  [stakingActionTypes.CLAIM]: RequestStatus.INIT,
  [stakingActionTypes.UNSTAKE]: RequestStatus.INIT,
};

const uiReducer = getUIReducer(initialState);

export default uiReducer;
