import React, { FC, ReactElement, ReactNode } from 'react';
import { Box, BoxProps, useTheme } from '@mui/material';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// eslint-disable-next-line import/no-unresolved
import 'swiper/css';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/pagination';

import { useSwiper } from '../hooks/useSwiper';
import { NavigationButtons } from '../NavigationButtons';

import { SLIDE_STEP } from './Carousel.constants';

export interface CarouselProps {
  dataId?: string;
  header?: ReactElement;
  children?: ReactNode;
  initialSlide?: number;
  loop?: boolean;
}

export const Carousel: FC<CarouselProps & BoxProps> = ({
  children,
  header,
  dataId,
  initialSlide = 0,
  loop,
  ...boxProps
}) => {
  const { setSwiper, isNextEnabled, isPrevEnabled, windowWidth, handleSliceChange, handleNextClick, handlePrevClick } =
    useSwiper(SLIDE_STEP, true);

  const theme = useTheme();

  return (
    <Box id={dataId} {...boxProps}>
      {header && <Box>{header}</Box>}
      <Swiper
        key={windowWidth}
        initialSlide={initialSlide}
        onSwiper={setSwiper}
        onSlideChange={handleSliceChange}
        loop={loop}
        breakpoints={{
          [theme.breakpoints.values.xs]: {
            slidesPerView: 1,
            spaceBetween: 8,
          },
          [theme.breakpoints.values.sm]: {
            slidesPerView: 2,
            spaceBetween: 16,
          },
          [theme.breakpoints.values.md]: {
            slidesPerView: 3,
            spaceBetween: 16,
          },
        }}
        pagination={{
          dynamicBullets: true,
          clickable: true,
        }}
        modules={[Pagination]}
      >
        {React.Children.map(children, (element) => (
          <SwiperSlide>{element}</SwiperSlide>
        ))}
      </Swiper>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          pr: { xs: 4, sm: 4, md: 10 },
          maxWidth: 1200,
          margin: '0 auto',
          mt: 2.5,
        }}
      >
        <NavigationButtons
          leftButtonProps={{
            disabled: loop ? false : !isPrevEnabled,
            onClick: handlePrevClick,
            sx: {
              mr: 2,
            },
          }}
          rightButtonProps={{
            disabled: loop ? false : !isNextEnabled,
            onClick: handleNextClick,
          }}
        />
      </Box>
    </Box>
  );
};
