import { buttonClasses, ComponentsOverrides, ComponentsProps, Theme } from '@mui/material';
import { COLOR_BLACK, COLOR_GREEN, COLOR_LIGHT_GREEN, COLOR_WHITE } from 'theme/colors';
import { FontWeights } from 'theme/Typography';
import { BORDER_GREEN, BORDER_GREEN_BOLD, BORDER_MAIN, BORDER_MAIN_BOLD, BORDER_RADIUS_MEDIUM } from 'theme/variables';

export const getMuiButtonOverrides = (theme: Theme): ComponentsOverrides['MuiButton'] => ({
  root: {
    textAlign: 'center',
    borderRadius: BORDER_RADIUS_MEDIUM,
    fontWeight: FontWeights.fontWeightLight,
    padding: theme.spacing(1, 2),
    minWidth: '75px',
    fontSize: '24px',
    lineHeight: '29px',
    fontFamily: '"Helvetica Neue", sans-serif',
    boxShadow: 'none',

    '&:hover': {
      boxShadow: 'none',
      backgroundColor: 'transparent',
    },

    '&:active': {
      boxShadow: 'none',
    },

    [`&.${buttonClasses.disabled}`]: {
      opacity: 0.3,
    },
  },

  startIcon: {
    '&>*:nth-of-type(1)': {
      fontSize: 'inherit',
    },
    margin: 0,
  },

  endIcon: {
    '&>*:nth-of-type(1)': {
      fontSize: 'inherit',
    },
    marginLeft: theme.spacing(1.25),
  },

  sizeSmall: {
    minWidth: '78px',
    fontSize: '19px',
    lineHeight: '22px',
    padding: theme.spacing(1, 2),
    height: '40px',
  },

  sizeMedium: {
    padding: theme.spacing(1.6, 2.5),
    height: '56px',
  },

  outlined: {
    border: BORDER_GREEN,

    '&:hover': {
      color: COLOR_GREEN,
      border: BORDER_GREEN,
    },

    '&:active': {
      color: COLOR_BLACK,
      border: BORDER_MAIN,
      backgroundColor: COLOR_LIGHT_GREEN,
    },
  },

  outlinedSecondary: {
    color: COLOR_BLACK,
    backgroundColor: COLOR_WHITE,
    border: BORDER_MAIN,
  },

  contained: {
    backgroundColor: COLOR_LIGHT_GREEN,
    border: BORDER_MAIN,
    color: COLOR_BLACK,

    '&:hover': {
      color: COLOR_GREEN,
      border: BORDER_GREEN,
    },

    '&:active': {
      color: COLOR_BLACK,
      border: BORDER_MAIN,
      backgroundColor: COLOR_WHITE,
    },

    '&.tertiary': {
      border: BORDER_MAIN_BOLD,
      textTransform: 'unset',
      fontWeight: FontWeights.fontWeightSemiBold,
      fontSize: 16,
      lineHeight: '20px',
      whiteSpace: 'nowrap',
      '&:hover': {
        border: BORDER_GREEN_BOLD,
      },
      [`&.${buttonClasses.disabled}`]: {
        background: 'transparent',
        color: 'rgb(50, 50, 50)',
      },
    },
  },

  text: {
    background: 'none',
    backgroundColor: 'transparent',
    transition: '0',
    boxSizing: 'border-box',
    borderColor: 'transparent',
    color: COLOR_BLACK,
    borderRadius: 0,
    padding: 0,
    margin: 0,
    minWidth: 'inherit',

    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
});

export const getMuiButtonDefaultProps = (): ComponentsProps['MuiButton'] => ({
  disableElevation: false,
  disableFocusRipple: true,
  disableRipple: true,
  variant: 'contained',
});
