import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Container, Link, Stack, Typography } from '@mui/material';
import { apiV1BaseUrl as baseURL } from 'appConstants';
import { SocialLinks } from 'components';
import { MainLogo } from 'components/Icon/components';
import { BORDER_GRAY } from 'theme/variables';

import { termsItems } from './Footer.helpers';

export const Footer: FC = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  return (
    <Container sx={{ pt: 11, pb: { xs: 2, sm: 2, md: 0 } }}>
      <Box
        pb={4}
        sx={{
          borderBottom: BORDER_GRAY,
        }}
      >
        <Stack spacing={2} alignItems="center">
          <MainLogo />
          <Stack alignItems="center">
            <Typography variant="h4" sx={{ textTransform: 'uppercase' }}>
              Agro Glory Time
            </Typography>
            <Typography variant="body2" sx={{ textTransform: 'uppercase' }}>
              Esse Gradum Ante
            </Typography>
          </Stack>
        </Stack>
        <Stack alignItems="center" mt={{ xs: 3, sm: 3, md: 4.5 }}>
          <SocialLinks />
        </Stack>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', py: { xs: 3 } }}>
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 0, md: 0.5 }}>
          <Typography component="span" variant="body2" color="gray">
            Copyright © {new Date().getFullYear()}.
          </Typography>
          <Typography component="span" variant="body2" color="gray">
            All Rights Reserved
          </Typography>
        </Stack>
        <Stack direction={{ xs: 'column', md: 'row' }}>
          <Link
            href={`${baseURL}/whitepaper/${language}`}
            target="_blank"
            rel="noreferrer"
            underline="none"
            sx={{ textAlign: 'end' }}
          >
            <Typography component="span" variant="body2" color="gray" align="right" pl={3}>
              {t('footer.litepaper')}
            </Typography>
          </Link>
          {termsItems.map(({ id, title, link }) => (
            <Link
              key={id}
              href={link(language)}
              target="_blank"
              rel="noreferrer"
              underline="none"
              sx={{ textAlign: 'end' }}
            >
              <Typography component="span" variant="body2" color="gray" align="right" pl={3}>
                {t(title)}
              </Typography>
            </Link>
          ))}
        </Stack>
      </Box>
    </Container>
  );
};
