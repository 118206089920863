/* eslint-disable camelcase */
import { validStatuses } from 'appConstants';
import apiActions from 'store/apiV2/actions';
import { baseApi } from 'store/apiV2/apiRequestBuilder';
import { call, put, takeLatest } from 'typed-redux-saga';

import { setDadReferralCode } from '../actions';
import actionTypes from '../actionTypes';
import { disconnectWalletState } from '../reducer';

import { getReferralInfoSaga } from './getReferralInfo';

export function* setDadReferralCodeSaga({
  type,
  payload: { refCode, successCallback },
}: ReturnType<typeof setDadReferralCode>) {
  yield put(apiActions.request(type));

  try {
    const { status } = yield* call(baseApi.setDadReferralCode, {
      promo_code: refCode,
    });
    if (validStatuses.includes(status)) {
      yield* call(getReferralInfoSaga, {
        type: actionTypes.GET_REFERRAL_INFO,
        payload: undefined,
      });
      successCallback();
      yield put(apiActions.success(type));
    } else {
      yield put(apiActions.error(type));
    }
  } catch (err) {
    console.error(err);
    yield put(apiActions.error(type, err));
    yield put(disconnectWalletState());
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.SET_DAD_REFERRAL_CODE, setDadReferralCodeSaga);
}
