import { error, request } from 'store/api/actions';
import { baseApi } from 'store/apiV2/apiRequestBuilder';
import { call, put, takeLatest } from 'typed-redux-saga';
import { RewardInfoModel } from 'types';
import { camelize, validateStatus } from 'utils';

import { getReferralRewardData } from '../actions';
import actionTypes from '../actionTypes';
import { updateUserState } from '../reducer';

export function* getReferralRewardDataSaga({ type }: ReturnType<typeof getReferralRewardData>) {
  yield put(request(type));
  try {
    const { data, status: resStatus } = yield call(baseApi.getReferralRewardData);
    if (!validateStatus(resStatus)) return;
    const camelizedData = data.map((el: any) => camelize(el)) as RewardInfoModel[];
    yield put(
      updateUserState({
        claimData: camelizedData,
      }),
    );
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    yield put(error(type));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_REFERRAL_REWARD_DATA, getReferralRewardDataSaga);
}
