import { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button, Typography } from '@mui/material';
import { Copy, Modal } from 'components';
import { useShallowSelector } from 'hooks';
import { setActiveModal } from 'store/modals/reducer';
import modalsSelector from 'store/modals/selectors';
import useSelector from 'store/user/selectors';
import { COLOR_GREEN } from 'theme/colors';
import { Modals, ModalsInitialState, State } from 'types/store';

import { modalData } from './index';

export const NotificationModal: FC = () => {
  const dispatch = useDispatch();
  const chainType = useShallowSelector(useSelector.getProp('chainType'));
  const { modalState } = useShallowSelector<State, ModalsInitialState>(modalsSelector.getModals);
  const { title, subtitle, Icon, body1, body2, isCopiable, repeatTransactions } = modalData[modalState.activeModal];

  const scanerLink =
    chainType === 'mainnet'
      ? `https://bscscan.com/tx/${modalState.txHash}`
      : `https://testnet.bscscan.com/tx/${modalState.txHash}`;

  const closeModal = useCallback(() => {
    dispatch(
      setActiveModal({
        activeModal: Modals.init,
        txHash: '',
        open: false,
      }),
    );
  }, [dispatch]);

  const ModalTitle = (
    <Typography pl={{ xs: 0, sm: 0, md: 5 }} variant="h4" textAlign="center" sx={{ span: { color: COLOR_GREEN } }}>
      {title} <span>{subtitle}</span>
    </Typography>
  );

  return (
    <Modal open={modalState.open} onClose={closeModal} title={ModalTitle}>
      <Box
        sx={{
          minWidth: { xs: '100%', sm: '100%', md: 550 },
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {Icon && <Icon />}

        {body1 && (
          <Typography my={3} fontSize="24px" fontWeight={500} align="center" maxWidth={537}>
            {body1}
          </Typography>
        )}

        {body2 && (
          <Typography variant="body1" align="center" maxWidth={537}>
            {body2}
          </Typography>
        )}

        {isCopiable && (
          <Copy sx={{ mt: 3, height: 48 }} copyText={scanerLink}>
            <Typography variant="body2" textTransform="none" maxWidth={{ xs: 250, sm: 250, md: 400 }} noWrap>
              {scanerLink}
            </Typography>
          </Copy>
        )}

        {repeatTransactions && (
          <Button onClick={modalState.repeatHandler} sx={{ mt: 3 }}>
            {repeatTransactions} again
          </Button>
        )}
      </Box>
    </Modal>
  );
};
