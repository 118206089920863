import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const InfoOutlined: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 72 71"
    sx={{
      width: '72px',
      height: '71px',
      color: 'transparent',
      ...sx,
    }}
  >
    <path
      d="M33.0669 33.2803H36.4704V45.8584"
      stroke="#323232"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M33.0403 45.8587H39.8749" stroke="#323232" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.693 58.6678L12.8294 50.8043C10.6099 48.5845 9.36319 45.5737 9.36377 42.4346V28.5661C9.36292 25.4263 10.6108 22.4152 12.8324 20.1964L20.6959 12.3329C22.9147 10.1113 25.9259 8.86341 29.0656 8.86426H42.9312C46.0709 8.86341 49.0821 10.1113 51.3008 12.3329L59.1673 20.1994C61.3889 22.4181 62.6368 25.4293 62.636 28.569V42.4317C62.6365 45.5708 61.3899 48.5815 59.1703 50.8013L51.3067 58.6678C49.0861 60.8883 46.0745 62.1364 42.9341 62.1364H29.0656C25.9251 62.1364 22.9132 60.889 20.693 58.6678Z"
      stroke="#323232"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36.2968 24.3948C36.2963 24.8032 35.965 25.1339 35.5566 25.1337C35.1482 25.1336 34.8172 24.8025 34.817 24.3942C34.8169 23.9858 35.1476 23.6545 35.556 23.654C35.7525 23.6537 35.9411 23.7317 36.0801 23.8707C36.2191 24.0097 36.2971 24.1982 36.2968 24.3948"
      stroke="#323232"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
