import { TelegramIcon, TwitterIcon } from 'components/Icon/components';

export const socialData = [
  {
    name: 'Telegram',
    icon: <TelegramIcon width="24" height="24" />,
  },
  {
    name: 'Twitter',
    icon: <TwitterIcon width="24" height="24" />,
  },
];
