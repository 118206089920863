import { FC } from 'react';
import { Box, IconButton, IconButtonProps } from '@mui/material';
import { ArrowRight } from 'components/Icon/components';

interface NavigationButtonsProps {
  leftButtonProps?: IconButtonProps;
  rightButtonProps?: IconButtonProps;
}

export const NavigationButtons: FC<NavigationButtonsProps> = ({ leftButtonProps, rightButtonProps }) => {
  return (
    <Box>
      <IconButton size="medium" aria-label="Previous" {...leftButtonProps}>
        <ArrowRight style={{ transform: 'rotate(180deg)' }} />
      </IconButton>
      <IconButton size="medium" aria-label="Next" {...rightButtonProps}>
        <ArrowRight />
      </IconButton>
    </Box>
  );
};
