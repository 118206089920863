import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const PlusIcon: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 41 41"
    sx={{
      ...sx,
      width: '41px',
      height: '41px',
      path: {
        stroke: '#1A1A1A',
        fill: 'none',
      },
    }}
  >
    <rect x="1" y="1" width="39" height="39" rx="19.5" fill="#B2D795" />
    <path
      d="M20.5 12.5V28.5M28.5 20.5L12.5 20.5"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect x="1" y="1" width="39" height="39" rx="19.5" stroke="black" />
  </SvgIcon>
);
