import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AirdropState } from 'types/store/airdrop';

const initialState: AirdropState = {
  isRegistered: false,
  registrationDates: {
    datetimeStart: '',
    datetimeEnd: '',
  },
  airdropData: [],
};

export const airdropReducer = createSlice({
  name: 'airdrop',
  initialState,
  reducers: {
    updateAirdropState: (state: AirdropState, action: PayloadAction<Partial<AirdropState>>) => ({
      ...state,
      ...action.payload,
    }),
    resetAirdropData: (state: AirdropState) => ({
      ...state,
      ...initialState,
    }),
  },
});

export const { updateAirdropState, resetAirdropData } = airdropReducer.actions;

export default airdropReducer.reducer;
