import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const ArrowRelation: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 156 25"
    sx={{
      width: '156px',
      height: '25px',
      ...sx,
    }}
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M79 -8.74228e-08L79 12L77 12L77 0L79 -8.74228e-08Z" fill="black" />
    <mask id="path-2-inside-1_1378_2674" fill="white">
      <path d="M64 25C64 17.8203 69.8203 12 77 12H79C86.1797 12 92 17.8203 92 25H64Z" />
    </mask>
    <path
      d="M62 25C62 16.7157 68.7157 10 77 10H79C87.2843 10 94 16.7157 94 25H90C90 18.9249 85.0751 14 79 14H77C70.9249 14 66 18.9249 66 25H62ZM92 25H64H92ZM62 25C62 16.7157 68.7157 10 77 10V14C70.9249 14 66 18.9249 66 25H62ZM79 10C87.2843 10 94 16.7157 94 25H90C90 18.9249 85.0751 14 79 14V10Z"
      fill="#323232"
      mask="url(#path-2-inside-1_1378_2674)"
    />
  </SvgIcon>
);
