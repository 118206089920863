export * from './routes';
export * from './resCodes';
export { default as URL } from './URL';

export const isProduction = true;

export const PROJECT_ID = process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID;

export const apiV1BaseUrl = isProduction
  ? 'https://agroglorytime.io/api/v1'
  : 'https://agti-token.rocknblock.io/api/v1';

export const apiV2BaseUrl = isProduction
  ? 'https://agroglorytime.io/api/v2'
  : 'https://agti-token.rocknblock.io/api/v2';

export const GAS_PRICE_MULTIPLIER = 1.3;

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';
