import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, styled, Typography } from '@mui/material';
import { Modal, ModalProps } from 'components';
import { MetamaskLogo, WalletConnectLogo } from 'modules/layout/assets';
import { COLOR_BLACK, COLOR_BLUE, COLOR_GRAY, COLOR_RED, COLOR_WHITE } from 'theme/colors';
import { FontWeights } from 'theme/Typography';
import { Chains, WalletProviders } from 'types';

export interface ConnectWalletModalProps extends ModalProps {
  onConnectWallet: (provider: WalletProviders, chain: Chains) => void;
}

const WalletButton = styled(Button)({
  width: '100%',
  maxWidth: '100%',
  height: '76px',
  background: COLOR_WHITE,
  color: COLOR_BLACK,
  fontSize: '22px',
  fontWeight: FontWeights.fontWeightMedium,

  '&:hover': {
    background: COLOR_WHITE,
    color: COLOR_BLACK,
  },
});

export const ConnectWalletModal: FC<ConnectWalletModalProps> = ({ onConnectWallet, onClose, open }) => {
  const handleConnect = (provider: WalletProviders, chain: Chains) => {
    onConnectWallet(provider, chain);
    onClose();
  };
  const { t } = useTranslation();

  return (
    <Modal open={open} onClose={onClose} title={t('header.connectWallet')}>
      <Box sx={{ maxWidth: '302px', minHeight: '227px' }}>
        <Typography variant="body1" className="s" color={COLOR_GRAY}>
          {t('header.connectWalletModalText')}
        </Typography>
        <Box mt={2}>
          <WalletButton
            sx={{
              borderColor: COLOR_RED,
              letterSpacing: '3.5px',
              '&:hover': {
                borderColor: COLOR_RED,
              },
            }}
            startIcon={<MetamaskLogo />}
            onClick={() => handleConnect(WalletProviders.metamask, Chains.bsc)}
          >
            MetaMask
          </WalletButton>
          <WalletButton
            sx={{
              borderColor: COLOR_BLUE,
              textTransform: 'none',
              mt: 1,
              '&:hover': {
                borderColor: COLOR_BLUE,
              },
            }}
            startIcon={<WalletConnectLogo />}
            onClick={() => handleConnect(WalletProviders.walletConnect, Chains.bsc)}
          >
            Wallet connect
          </WalletButton>
        </Box>
      </Box>
    </Modal>
  );
};
