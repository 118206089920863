export const useShareSocials = (shareText: string, shareType: 'link' | 'code' = 'code') => {
  return (socialType: string) => {
    const url = 'https://agroglorytime.io/';
    const text = `Hello! Participate in the real Ukrainian agricultural business and earn income. ${
      shareType === 'link' ? '' : 'Visit agroglorytime.io. '
    }The code gives you a discount and the opportunity to make money by promoting the project. Here's my ${
      shareType === 'link' ? 'referral link' : 'promo code'
    }: ${shareText}`;
    if (socialType === 'Twitter') {
      window.open(
        `http://twitter.com/share?url=${encodeURIComponent(url)}&text=${encodeURIComponent(text)}`,
        '',
        'left=0,top=0,width=550,height=450,personalbar=0,toolbar=0,scrollbars=0,resizable=0',
      );
    } else {
      window.open(
        `https://telegram.me/share/url?url=${encodeURIComponent(url)}&text=${encodeURIComponent(text)}`,
        '',
        'left=0,top=0,width=550,height=450,personalbar=0,toolbar=0,scrollbars=0,resizable=0',
      );
    }
  };
};
