import { FC, RefObject } from 'react';
import { Popover, Stack, Typography } from '@mui/material';
import { ModalProps } from 'components';
import { COLOR_WHITE } from 'theme/colors';
import { FontWeights } from 'theme/Typography';
import { BORDER_MAIN, BORDER_RADIUS_DEFAULT } from 'theme/variables';
import i18n from 'utils/i18n';

import { langs } from './LanguageModal.helper';

export type LanguageModalProps = {
  anchorEl: RefObject<HTMLElement>;
} & ModalProps;

export const LanguageModal: FC<LanguageModalProps> = ({ open, anchorEl, onClose }) => {
  const handleLanguageClick = (value: string) => {
    onClose();
    i18n.changeLanguage(value);
  };
  return (
    <Popover
      anchorEl={anchorEl?.current}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiPopover-paper': {
          mt: 1,
          p: 3,
          pt: 2,
          width: 180,
          background: COLOR_WHITE,
          borderRadius: BORDER_RADIUS_DEFAULT,
          border: BORDER_MAIN,
          overflow: 'hidden',
        },
      }}
    >
      <Stack spacing={1}>
        {langs.map(({ value, label }) => (
          <Typography
            sx={{ cursor: 'pointer' }}
            fontWeight={FontWeights.fontWeightLight}
            textAlign="center"
            key={value}
            variant="body1"
            className="l"
            onClick={() => handleLanguageClick(value)}
          >
            {label}
          </Typography>
        ))}
      </Stack>
    </Popover>
  );
};
