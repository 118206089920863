import { error, request } from 'store/api/actions';
import { baseApi } from 'store/apiV2/apiRequestBuilder';
import { call, put, takeLatest } from 'typed-redux-saga';
import { validateStatus } from 'utils';

import { getReferralTotalEarned } from '../actions';
import actionTypes from '../actionTypes';
import { updateUserState } from '../reducer';

export function* getReferralTotalEarnedSaga({ type }: ReturnType<typeof getReferralTotalEarned>) {
  yield put(request(type));
  try {
    const {
      data: { amount },
      status: resStatus,
    } = yield call(baseApi.getReferralTotalEarned);
    if (!validateStatus(resStatus)) return;
    yield put(
      updateUserState({
        totalEarned: amount,
      }),
    );
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    yield put(error(type));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_REFERRAL_REWARD_DATA, getReferralTotalEarnedSaga);
}
