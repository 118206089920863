import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Circles: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 275 668"
    sx={{
      width: '275px',
      height: '668px',
      color: 'transparent',
      ...sx,
    }}
  >
    <path
      d="M430.991 537.101C379.824 608.678 280.088 625.39 208.223 574.427C136.358 523.464 119.58 424.126 170.747 352.549C221.913 280.973 321.65 264.261 393.515 315.224C465.379 366.187 482.157 465.525 430.991 537.101Z"
      stroke="#3D7C0B"
    />
    <path
      d="M477.63 488.244C426.463 559.821 326.727 576.533 254.862 525.57C182.997 474.607 166.219 375.268 217.386 303.692C268.552 232.115 368.289 215.403 440.154 266.366C512.019 317.329 528.796 416.667 477.63 488.244Z"
      stroke="#3D7C0B"
    />
    <path
      d="M452.781 403.155C387.232 494.851 259.461 516.26 167.396 450.972C75.331 385.684 53.8369 258.423 119.386 166.727C184.935 75.0312 312.706 53.6217 404.771 118.91C496.836 184.198 518.33 311.459 452.781 403.155Z"
      stroke="#3D7C0B"
    />
  </SvgIcon>
);
