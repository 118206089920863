import { fork } from 'redux-saga/effects';

import buyTokens from './buyTokens';
import claimTokens from './claimTokens';
import getBuyData from './getBuyData';
import getClaimData from './getClaimData';

export default function* crowdsaleSagas() {
  yield fork(buyTokens);
  yield fork(claimTokens);
  yield fork(getBuyData);
  yield fork(getClaimData);
}
