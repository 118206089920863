/* eslint-disable camelcase */
import { Nullable } from 'types';
import { Chains, IChainType } from 'types/connect';

// *will delete later becouse of lots of usage of this type (errors)*
export type UserState = {
  address: string;
  provider: string;
  chainType: IChainType;
  network: Chains;
  tokenBalance: string;
  tokenDecimals: number;
  nativeBalance: string;
  rankId: string;
  key: string;
  referralInfo: Nullable<ReferralInfoModel>;
  claimData: Nullable<RewardInfoModel[]>;
  totalEarned: string;
};

export interface ReferralInfoModel {
  status: 'PROMOTER' | 'MASTER' | 'GURU';
  statusExpiresAt: string;
  referralPercent: number;
  address: string;
  promoCode: string;
  dadPromoCode: string;
  dadAddress: string;
  dadReferralPercent: number;
  usersInvited: number;
}

export const REF_STATUS = {
  PROMOTER: '7%',
  MASTER: '10%',
  GURU: '15%',
};

export interface RewardInfoModel {
  proof: string[];
  amount: string;
  claimed: boolean;
  createdAt: string;
  airdropContractId: number;
  token: {
    address: string;
    symbol: string;
    decimals: number;
  };
}
