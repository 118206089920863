import { FC, RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Popover, Stack, Typography } from '@mui/material';
import { ModalProps } from 'components';
import { COLOR_GREEN, COLOR_WHITE } from 'theme/colors';
import { BORDER_MAIN, BORDER_RADIUS_DEFAULT } from 'theme/variables';

export type HowToBuyModalProps = {
  anchorEl: RefObject<HTMLElement>;
} & ModalProps;

export const HowToBuyModal: FC<HowToBuyModalProps> = ({ open, anchorEl, onClose }) => {
  const { t } = useTranslation();

  const instructions = t('HowToBuy.instructions', { returnObjects: true }) as [];

  return (
    <Popover
      anchorEl={anchorEl?.current}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiPopover-paper': {
          mt: 1,
          p: 2.25,
          pb: 3,
          width: 326,
          background: COLOR_WHITE,
          borderRadius: BORDER_RADIUS_DEFAULT,
          border: BORDER_MAIN,
          overflow: 'hidden',
        },
      }}
    >
      <Typography mb={2} fontSize={18} fontWeight={400} color={COLOR_GREEN} textAlign="center">
        {t('HowToBuy.title')}
      </Typography>
      <Stack
        sx={{
          height: 245,
          overflowY: 'auto',
        }}
        spacing={1}
      >
        {instructions.map((text, index) => (
          // not important, not rerender intems
          // eslint-disable-next-line react/no-array-index-key
          <Box key={index} display="flex" flexDirection="row" alignItems="flex-start">
            <Typography mr={1} fontSize={14} maxWidth={278}>
              {index + 1}.
            </Typography>
            <Typography fontSize={14} maxWidth={278}>
              {text}
            </Typography>
          </Box>
        ))}
      </Stack>
    </Popover>
  );
};
