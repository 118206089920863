import { contractsConfig, ContractsNames } from 'services/WalletService/config';
import apiActions from 'store/api/actions';
import store from 'store/configureStore';
import { setActiveModal } from 'store/modals/reducer';
import userActionTypes from 'store/user/actionTypes';
import { approveSaga } from 'store/user/sagas/approve';
import { getTokenBalanceSaga } from 'store/user/sagas/getTokenBalance';
import userSelector from 'store/user/selectors';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import { Modals, UserState } from 'types';
import { StakingAbi } from 'types/contracts/StakingAbi';
import { estimateGasForMethod } from 'utils';
import { AbiItem } from 'web3-utils';

import { stake } from '../actions';
import actionTypes from '../actionTypes';

import { getOneStakeDataSaga } from './getOneStakeData';

export function* stakeSaga({
  type,
  payload: { web3Provider, poolId, amount },
}: ReturnType<typeof stake>): Generator<unknown, void, any> {
  yield* put(apiActions.request(type));
  const { address: userAddress, network, chainType }: UserState = yield select(userSelector.getUser);

  const { address: stakingContractAddress, abi: stakingAbi } =
    contractsConfig.contracts[ContractsNames.staking][chainType];
  const { address: agtiAddress } = contractsConfig.contracts[ContractsNames.agti][chainType];

  const stakingContract: StakingAbi = yield new web3Provider.eth.Contract(
    stakingAbi as AbiItem[],
    stakingContractAddress[network],
  );

  try {
    yield call(approveSaga, {
      type: userActionTypes.APPROVE,
      payload: {
        web3Provider,
        amount,
        spender: stakingContractAddress[network],
        tokenAddress: agtiAddress[network],
      },
    });

    yield* put(
      setActiveModal({
        activeModal: Modals.SendPendingSimple,
        open: true,
      }),
    );

    const { estimatedGas, gasPrice } = yield* call(estimateGasForMethod, {
      web3Provider,
      contract: stakingContract,
      method: 'stake',
      estimateGasArgs: { from: userAddress },
      methodArgs: [poolId, amount],
    });

    const { transactionHash } = yield call(stakingContract.methods.stake(poolId, amount).send, {
      from: userAddress,
      gasPrice: Number(gasPrice),
      gas: estimatedGas,
    });

    yield* put(
      setActiveModal({
        activeModal: Modals.SendSuccess,
        open: true,
        txHash: transactionHash,
      }),
    );

    yield call(getOneStakeDataSaga, {
      type: actionTypes.GET_ONE_STAKE_DATA,
      payload: {
        web3Provider,
        poolId,
      },
    });

    yield call(getTokenBalanceSaga, {
      type: userActionTypes.GET_TOKEN_BALANCE,
      payload: { web3Provider },
    });

    yield* put(apiActions.success(type));
  } catch (err) {
    console.error(err);
    yield* put(apiActions.error(type, err));
    yield* put(
      setActiveModal({
        activeModal: Modals.SendRejected,
        open: true,
        repeatHandler: () =>
          store.store.dispatch({
            type,
            payload: {
              web3Provider,
              poolId,
              amount,
            },
          }),
      }),
    );
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.STAKE, stakeSaga);
}
