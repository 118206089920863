import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material';
import { BORDER_RADIUS_SMALL } from 'theme/variables';

import { COLOR_BLACK, COLOR_GRAY_LIGHT_2 } from '../colors';
import { FontFamilies, FontWeights } from '../Typography';

export const getMuiTooltipOverrides = (theme: Theme): ComponentsOverrides['MuiTooltip'] => ({
  tooltip: {
    borderRadius: BORDER_RADIUS_SMALL,
    background: COLOR_BLACK,
    fontWeight: FontWeights.fontWeightRegular,
    fontFamily: FontFamilies.primary,
    fontSize: '12px',
    lineHeight: '16px',
    padding: theme.spacing(1, 1.5),
    color: COLOR_GRAY_LIGHT_2,
    maxWidth: 172,
    whiteSpace: 'pre-line',
    // wordWrap: 'unset',
    arrow: {
      background: COLOR_BLACK,
    },
  },
  arrow: {
    '&::before': {
      color: COLOR_BLACK,
      borderRadius: '1px',
    },
  },
});

export const getMuiTooltipDefaultProps = (): ComponentsProps['MuiTooltip'] => ({
  arrow: true,
  placement: 'bottom-end',
  componentsProps: {
    popper: {
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [35, -20],
          },
        },
      ],
    },
  },
});
