import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Users: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 32 32"
    sx={{
      ...sx,
      width: '32px',
      height: '32px',
    }}
  >
    <path
      d="M14.4001 9.59999C14.4001 12.251 12.2511 14.4 9.6001 14.4C6.94913 14.4 4.8001 12.251 4.8001 9.59999C4.8001 6.94902 6.94913 4.79999 9.6001 4.79999C12.2511 4.79999 14.4001 6.94902 14.4001 9.59999Z"
      fill="#1A1A1A"
    />
    <path
      d="M27.2001 9.59999C27.2001 12.251 25.0511 14.4 22.4001 14.4C19.7491 14.4 17.6001 12.251 17.6001 9.59999C17.6001 6.94902 19.7491 4.79999 22.4001 4.79999C25.0511 4.79999 27.2001 6.94902 27.2001 9.59999Z"
      fill="#1A1A1A"
    />
    <path
      d="M21.8399 27.2C21.2313 27.2 20.767 26.6602 20.7912 26.052C20.7971 25.9021 20.8001 25.7514 20.8001 25.6C20.8001 22.9837 19.9031 20.5769 18.3997 18.6705C19.5765 17.9897 20.9428 17.6 22.4001 17.6C26.8184 17.6 30.4001 21.1817 30.4001 25.6V26.2C30.4001 26.7523 29.9524 27.2 29.4001 27.2H21.8399Z"
      fill="#1A1A1A"
    />
    <path
      d="M9.6001 17.6C14.0184 17.6 17.6001 21.1817 17.6001 25.6V26.2C17.6001 26.7523 17.1524 27.2 16.6001 27.2H2.6001C2.04781 27.2 1.6001 26.7523 1.6001 26.2V25.6C1.6001 21.1817 5.18182 17.6 9.6001 17.6Z"
      fill="#1A1A1A"
    />
  </SvgIcon>
);
