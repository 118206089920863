import { ZERO_ADDRESS } from 'appConstants';
import BigNumber from 'bignumber.js';
import { contractsConfig, ContractsNames } from 'services/WalletService/config';
import apiActions from 'store/api/actions';
import userSelector from 'store/user/selectors';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import { PastStake, UserStakes, UserState } from 'types';
import { StakingAbi } from 'types/contracts/StakingAbi';
import { fromDecimalsAgti, isFutureTimestamp, isPastTimestamp } from 'utils';
import { AbiItem } from 'web3-utils';

import { getStakingData } from '../actions';
import actionTypes from '../actionTypes';
import { updateStakingState, updateUserStakesState } from '../reducer';

export function* getStakingDataSaga({ type, payload: { web3Provider } }: ReturnType<typeof getStakingData>) {
  yield* put(apiActions.request(type));
  const { address: userAddress, network, chainType }: UserState = yield select(userSelector.getUser);

  const { address: stakingContractAddress, abi: stakingAbi } =
    contractsConfig.contracts[ContractsNames.staking][chainType];

  const stakingContract: StakingAbi = yield new web3Provider.eth.Contract(
    stakingAbi as AbiItem[],
    stakingContractAddress[network],
  );

  try {
    const totalPools = yield* call(stakingContract.methods.totalPools().call);
    const stakingData = yield* call(
      stakingContract.methods.getPoolsAndUserData(userAddress || ZERO_ADDRESS, 0, totalPools).call,
    );

    if (stakingData[0].length) {
      const pools = stakingData[0];

      const litePoolIndex = pools.findLastIndex(
        // (pool) => isFutureTimestamp(pool[2]) && isPastTimestamp(pool[0]) && pool[3].slice(0, 5) === ELockType.Lite,
        (pool) => isFutureTimestamp(pool[2]) && isPastTimestamp(pool[0]) && pool[8],
      );
      const litePool = pools[litePoolIndex];

      const hardPoolIndex = pools.findLastIndex(
        (pool) => isFutureTimestamp(pool[2]) && isPastTimestamp(pool[0]) && !pool[8],
      );
      const hardPool = pools[hardPoolIndex];

      const litePoolData = litePool
        ? {
            id: litePoolIndex,
            acceptStakesStart: litePool[0],
            claimStart: litePool[1],
            unstakeStart: litePool[2],
            apr: +litePool[3],
            totalStaked: litePool[4],
            totalStakedCurrent: litePool[5],
            totalRewardAmount: litePool[6],
            currentRewardAmount: litePool[7],
            claimAvailable: litePool[8],
            cancelled: litePool[9],
          }
        : null;

      const hardPoolData = hardPool
        ? {
            id: hardPoolIndex,
            acceptStakesStart: hardPool[0],
            claimStart: hardPool[1],
            unstakeStart: hardPool[2],
            apr: +hardPool[3],
            totalStaked: hardPool[4],
            totalStakedCurrent: hardPool[5],
            totalRewardAmount: hardPool[6],
            currentRewardAmount: hardPool[7],
            claimAvailable: hardPool[8],
            cancelled: hardPool[9],
          }
        : null;

      yield put(
        updateStakingState({
          litePool: litePoolData,
          hardPool: hardPoolData,
        }),
      );

      if (userAddress) {
        const stakeData = stakingData[1];

        const userStakesData: UserStakes = {
          liteStake: null,
          hardStake: null,
          pastStakes: [],
        };

        if (userAddress && (litePoolIndex !== -1 || hardPoolIndex !== -1)) {
          if (litePoolIndex !== -1) {
            const liteStake = stakeData[litePoolIndex];
            const liteShare = +liteStake[0]
              ? parseFloat(
                  new BigNumber(liteStake[0])
                    .dividedBy(new BigNumber(litePoolData?.totalStaked || 0).dividedBy(100))
                    .toFixed(2, 0),
                )
              : 0;
            userStakesData.liteStake = {
              poolId: litePoolIndex,
              stake: liteStake[0],
              claimed: liteStake[1],
              amountToClaim: fromDecimalsAgti(stakingData[2][litePoolIndex]),
              share: liteShare,
            };
          }
          if (hardPoolIndex !== -1) {
            const hardStake = stakeData[hardPoolIndex];
            const hardShare = +hardStake[0]
              ? parseFloat(
                  new BigNumber(hardStake[0])
                    .dividedBy(new BigNumber(hardPoolData?.totalStaked || 0).dividedBy(100))
                    .toFixed(2, 0),
                )
              : 0;
            userStakesData.hardStake = {
              poolId: hardPoolIndex,
              stake: hardStake[0],
              claimed: hardStake[1],

              amountToClaim: fromDecimalsAgti(stakingData[2][hardPoolIndex]),

              share: hardShare,
            };
          }
        }
        userStakesData.pastStakes = pools.reduce((acc, pool, id) => {
          if (isPastTimestamp(pool[2]) && +stakeData[id][0] !== 0) {
            const share = +stakeData[id][0]
              ? parseFloat(
                  new BigNumber(stakeData[id][0]).dividedBy(new BigNumber(pool[4] || 0).dividedBy(100)).toFixed(2, 0),
                )
              : 0;
            return [
              ...acc,
              {
                poolData: {
                  id,
                  acceptStakesStart: pool[0],
                  claimStart: pool[1],
                  unstakeStart: pool[2],
                  apr: +pool[3],
                  totalStaked: pool[4],
                  totalStakedCurrent: pool[5],
                  totalRewardAmount: pool[6],
                  currentRewardAmount: pool[7],
                  claimAvailable: pool[8],
                  cancelled: pool[9],
                },
                userStake: {
                  poolId: id,
                  stake: stakeData[id][0],
                  claimed: stakeData[id][1],
                  amountToClaim: fromDecimalsAgti(stakingData[2][id]),
                  share,
                },
              },
            ];
          }
          return acc;
        }, [] as Array<PastStake>);

        yield put(updateUserStakesState(userStakesData));
      }
    }
    yield* put(apiActions.success(type));
  } catch (err) {
    console.log(err);
    yield* put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_STAKING_DATA, getStakingDataSaga);
}
