import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const TelegramIcon: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 30 30"
    sx={{
      ...sx,
      width: '30px',
      height: '30px',
    }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30 15C30 23.2843 23.2843 30 15 30C6.71573 30 0 23.2843 0 15C0 6.71573 6.71573 0 15 0C23.2843 0 30 6.71573 30 15ZM15.5389 11.0736C14.0799 11.6804 11.164 12.9364 6.79124 14.8416C6.08116 15.124 5.7092 15.4002 5.67533 15.6703C5.61811 16.1268 6.18976 16.3066 6.96821 16.5514C7.0741 16.5846 7.18382 16.6191 7.2963 16.6557C8.06217 16.9047 9.09241 17.1959 9.62798 17.2075C10.1138 17.218 10.656 17.0177 11.2547 16.6066C15.3403 13.8487 17.4494 12.4547 17.5818 12.4247C17.6752 12.4035 17.8046 12.3768 17.8923 12.4548C17.98 12.5327 17.9714 12.6803 17.9621 12.72C17.9055 12.9614 15.6615 15.0476 14.5003 16.1272C14.1383 16.4637 13.8815 16.7025 13.829 16.757C13.7114 16.8791 13.5915 16.9947 13.4763 17.1057C12.7648 17.7916 12.2313 18.306 13.5059 19.1459C14.1184 19.5496 14.6086 19.8834 15.0975 20.2164C15.6316 20.58 16.1642 20.9428 16.8534 21.3945C17.029 21.5096 17.1967 21.6292 17.36 21.7456C17.9815 22.1887 18.5398 22.5867 19.2296 22.5232C19.6304 22.4864 20.0444 22.1095 20.2547 20.9854C20.7516 18.3289 21.7284 12.573 21.9541 10.2012C21.9739 9.99336 21.949 9.72741 21.9291 9.61067C21.9091 9.49392 21.8673 9.32758 21.7156 9.20445C21.5359 9.05862 21.2584 9.02787 21.1343 9.03005C20.5702 9.03999 19.7046 9.34097 15.5389 11.0736Z"
      fill="#323232"
    />
  </SvgIcon>
);
