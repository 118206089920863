import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const BurgerIcon: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 22 18"
    sx={{
      ...sx,
      width: '22px',
      height: '18px',
    }}
  >
    <path d="M1 1H21M1 9H21M1 17H9.75" stroke="#323232" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </SvgIcon>
);
