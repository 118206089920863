import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Copy: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 25 24"
    sx={{
      ...sx,
      width: '25px',
      height: '27px',
    }}
  >
    <path
      d="M15.7929 3.29289L16.5 2.58579L16.5 2.58579L15.7929 3.29289ZM20.2071 7.70711L20.9142 7L20.2071 7.70711ZM10.5 4H15.0858V2H10.5V4ZM19.5 8.41421V15H21.5V8.41421H19.5ZM15.0858 4L19.5 8.41421L20.9142 7L16.5 2.58579L15.0858 4ZM21.5 8.41421C21.5 7.88378 21.2893 7.37507 20.9142 7L19.5 8.41421H19.5H21.5ZM15.0858 4L15.0858 4L16.5 2.58579C16.1249 2.21071 15.6162 2 15.0858 2V4ZM10.5 16C9.94772 16 9.5 15.5523 9.5 15H7.5C7.5 16.6569 8.84315 18 10.5 18V16ZM18.5 18C20.1569 18 21.5 16.6569 21.5 15H19.5C19.5 15.5523 19.0523 16 18.5 16V18ZM9.5 5C9.5 4.44772 9.94772 4 10.5 4V2C8.84315 2 7.5 3.34315 7.5 5H9.5ZM6.5 22H14.5V20H6.5V22ZM3.5 9V19H5.5V9H3.5ZM6.5 20C5.94772 20 5.5 19.5523 5.5 19H3.5C3.5 20.6569 4.84315 22 6.5 22V20ZM9.5 15V7H7.5V15H9.5ZM9.5 7V5H7.5V7H9.5ZM6.5 8H8.5V6H6.5V8ZM18.5 16H16.5V18H18.5V16ZM16.5 16H10.5V18H16.5V16ZM17.5 19V17H15.5V19H17.5ZM5.5 9C5.5 8.44772 5.94772 8 6.5 8V6C4.84315 6 3.5 7.34315 3.5 9H5.5ZM14.5 22C16.1569 22 17.5 20.6569 17.5 19H15.5C15.5 19.5523 15.0523 20 14.5 20V22Z"
      fill="currentColor"
    />
  </SvgIcon>
);
