import BigNumber from 'bignumber.js';
import { contractsConfig, ContractsNames } from 'services/WalletService/config';
import apiActions from 'store/api/actions';
import userSelector from 'store/user/selectors';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import { UserState } from 'types';
import { StakingAbi } from 'types/contracts/StakingAbi';
import { fromDecimalsAgti } from 'utils';
import { AbiItem } from 'web3-utils';

import { getOneStakeData } from '../actions';
import actionTypes from '../actionTypes';
import { updateStakingState, updateUserStakesState } from '../reducer';

export function* getOneStakeDataSaga({ type, payload: { web3Provider, poolId } }: ReturnType<typeof getOneStakeData>) {
  yield* put(apiActions.request(type));
  const { address: userAddress, network, chainType }: UserState = yield select(userSelector.getUser);

  const { address: stakingContractAddress, abi: stakingAbi } =
    contractsConfig.contracts[ContractsNames.staking][chainType];

  const stakingContract: StakingAbi = yield new web3Provider.eth.Contract(
    stakingAbi as AbiItem[],
    stakingContractAddress[network],
  );

  try {
    const pool = yield* call(stakingContract.methods.poolInfo(poolId).call);

    const poolData = {
      id: poolId,
      acceptStakesStart: pool[0],
      claimStart: pool[1],
      unstakeStart: pool[2],
      apr: +pool[3],
      totalStaked: pool[4],
      totalStakedCurrent: pool[5],
      totalRewardAmount: pool[6],
      currentRewardAmount: pool[7],
      claimAvailable: pool[8],
      cancelled: pool[9],
    };

    if (poolData.claimAvailable) {
      yield put(
        updateStakingState({
          litePool: poolData,
        }),
      );
    } else {
      yield put(
        updateStakingState({
          hardPool: poolData,
        }),
      );
    }

    if (userAddress) {
      const stakeInfo = yield* call(stakingContract.methods.stakeInfo(poolId, userAddress).call);

      const amountToClaimWithDecimals = yield* call(
        stakingContract.methods.amountToClaimWithDecimals(poolId, userAddress).call,
      );

      const share = parseFloat(
        new BigNumber(stakeInfo[0]).dividedBy(new BigNumber(poolData?.totalStaked || 0).dividedBy(100)).toFixed(2, 0),
      );

      const stakeData = {
        poolId,
        stake: stakeInfo[0],
        claimed: stakeInfo[1],
        amountToClaim: fromDecimalsAgti(amountToClaimWithDecimals),

        share,
      };

      if (poolData.claimAvailable) {
        yield put(
          updateUserStakesState({
            liteStake: stakeData,
          }),
        );
      } else {
        yield put(
          updateUserStakesState({
            hardStake: stakeData,
          }),
        );
      }
    }

    yield* put(apiActions.success(type));
  } catch (err) {
    console.log(err);
    yield* put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_ONE_STAKE_DATA, getOneStakeDataSaga);
}
