import { contractsConfig, ContractsNames } from 'services/WalletService/config';
import apiActions from 'store/api/actions';
import userSelector from 'store/user/selectors';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import { UserState } from 'types';
import { CrowdsaleAbi } from 'types/contracts/CrowdsaleAbi';
import { AbiItem } from 'web3-utils';

import { getClaimData } from '../actions';
import actionTypes from '../actionTypes';
import { updateCrowdsaleState } from '../reducer';

export function* getClaimDataSaga({ type, payload: { web3Provider } }: ReturnType<typeof getClaimData>) {
  yield* put(apiActions.request(type));
  const { address: userAddress, network, chainType }: UserState = yield select(userSelector.getUser);

  const { address: crowdsaleContractAddress, abi: crowdsaleAbi } =
    contractsConfig.contracts[ContractsNames.crowdsale][chainType];

  const crowdsaleContract: CrowdsaleAbi = yield new web3Provider.eth.Contract(
    crowdsaleAbi as AbiItem[],
    crowdsaleContractAddress[network],
  );

  try {
    const currentVestingPeriod = yield* call(crowdsaleContract.methods.currentVestingPeriod().call);

    const allVestingPeriodsArray = Array(+currentVestingPeriod + 1)
      .fill('')
      .map((_, index) => index);

    const vestingPeriodBuys = yield* call(
      crowdsaleContract.methods.getAmounts(allVestingPeriodsArray, userAddress).call,
    );

    const updatedVestingPeriodBuys = vestingPeriodBuys.map((vestingAmount, vestingPeriod) => ({
      vestingAmount,
      vestingPeriod: String(vestingPeriod),
    }));

    yield put(
      updateCrowdsaleState({
        vestingPeriodBuys: updatedVestingPeriodBuys,
        currentVestingPeriod,
      }),
    );
    yield* put(apiActions.success(type));
  } catch (err) {
    console.log(err);
    yield* put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_CLAIM_DATA, getClaimDataSaga);
}
