import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, styled, Typography } from '@mui/material';
import { DateLike, useTimeLeft } from 'hooks/useTimeLeft';
import { COLOR_LIGHT_GREEN } from 'theme/colors';
import { FontFamilies, FontWeights } from 'theme/Typography';
import { BORDER_MAIN_BOLD } from 'theme/variables';

export interface CountdownProps {
  endTime: DateLike;
}

const Wrapper = styled(Box)(({ theme }) => ({
  border: BORDER_MAIN_BOLD,
  borderRadius: 100,
  padding: theme.spacing(0, 1),
  width: 'fit-content',
  '&.accent': {
    background: COLOR_LIGHT_GREEN,
  },
}));

export const Countdown: FC<CountdownProps> = ({ endTime }) => {
  const { t } = useTranslation();
  const timeLeft = useTimeLeft(endTime, true);

  if (!timeLeft || (+timeLeft.seconds === 0 && +timeLeft.minutes === 0)) {
    return (
      <Wrapper className="accent">
        <Typography
          variant="body2"
          fontWeight={FontWeights.fontWeightBold}
          lineHeight="20px"
          fontFamily={FontFamilies.secondary}
          whiteSpace="nowrap"
        >
          {t('StakingPageText.finished')}
        </Typography>
      </Wrapper>
    );
  }

  const { days, hours, minutes } = timeLeft;

  return (
    <Wrapper>
      <Typography
        variant="body2"
        fontWeight={FontWeights.fontWeightBold}
        lineHeight="20px"
        fontFamily={FontFamilies.secondary}
        whiteSpace="nowrap"
      >
        {days}
        {t('countdown.daysShort')} {hours}
        {t('countdown.hoursShort')} {minutes}
        {t('countdown.minutesShort')}
      </Typography>
    </Wrapper>
  );
};
