import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material';
import { CheckIcon } from 'components/Icon/components';
import { COLOR_BEIGE, COLOR_BLACK } from 'theme/colors';
import { BORDER_GREEN_BOLD } from 'theme/variables';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getMuiCheckbox = (theme: Theme): ComponentsOverrides['MuiCheckbox'] => {
  const colorCommon = {
    background: COLOR_BEIGE,
    borderColor: COLOR_BLACK,
    color: 'transparent',
    '&:hover': {
      border: BORDER_GREEN_BOLD,
      background: COLOR_BEIGE,
    },
    '&, &:hover, &:focus, &:active': {
      opacity: 1,
    },
    '&, &:focus': {
      color: 'COLOR_GREEN',
    },
    '&:hover, &:active': {
      color: 'transparent',
      '& > span > svg > path': {
        fill: 'none',
      },
    },
    '&.Mui-disabled': {
      opacity: '0.3',
      background: COLOR_BLACK,
      '& svg > path': {
        display: 'none',
      },
    },
  };
  return {
    root: {
      padding: 0,
      height: 30,
      width: 30,
      borderRadius: 8,
      borderWidth: 1,
      borderStyle: 'solid',
      overflow: 'hidden',
      '&.Mui-checked': {
        border: 0,
      },
    },
    colorPrimary: colorCommon,
    colorSecondary: colorCommon,
  };
};

export const getMuiCheckboxDefaultProps = (): ComponentsProps['MuiCheckbox'] => ({
  checkedIcon: <CheckIcon />,
  color: 'primary',
});
