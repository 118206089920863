import { FC } from 'react';
import { SvgIconProps } from '@mui/material';

import {
  AgtiToken,
  ArrowDown,
  ArrowRelation,
  ArrowRight,
  BurgerIcon,
  CheckIcon,
  Circles,
  Close,
  Copy,
  Fire,
  Info,
  InfoOutlined,
  LoaderIcon,
  LogoSmall,
  MainLogo,
  MediumIcon,
  PlusIcon,
  RejectStepIcon,
  SelectArrowDown,
  SelectCheckIcon,
  SuccessStepIcon,
  TelegramIcon,
  TwitterIcon,
  WalletIcon,
  WarningIcon,
} from './components';

/* eslint-disable @typescript-eslint/no-shadow */
export enum IconKey {
  MainLogo = 'MainLogo',
  ArrowDown = 'ArrowDown',
  Copy = 'Copy',
  Close = 'Close',
  TwitterIcon = 'TwitterIcon',
  TelegramIcon = 'TelegramIcon',
  MediumIcon = 'MediumIcon',
  CheckIcon = 'CheckIcon',
  WalletIcon = 'WalletIcon',
  SelectArrowDown = 'SelectArrowDown',
  SelectCheckIcon = 'SelectCheckIcon',
  SuccessStepIcon = 'SuccessStepIcon',
  LoaderIcon = 'LoaderIcon',
  BurgerIcon = 'BurgerIcon',
  ArrowRight = 'ArrowRight',
  LogoSmall = 'LogoSmall',
  WarningIcon = 'WarningIcon',
  RejectStepIcon = 'RejectStepIcon',
  PlusIcon = 'PlusIcon',
  AgtiToken = 'AgtiToken',
  ArrowRelation = 'ArrowRelation',
  Fire = 'Fire',
  Info = 'Info',
  Circles = 'Circles',
  InfoOutlined = 'InfoOutlined',
}

/* eslint-enable @typescript-eslint/no-shadow */
export const Icon: Record<IconKey, FC<SvgIconProps>> = {
  MainLogo,
  ArrowDown,
  Copy,
  TwitterIcon,
  TelegramIcon,
  MediumIcon,
  Close,
  CheckIcon,
  WalletIcon,
  SelectArrowDown,
  SelectCheckIcon,
  LoaderIcon,
  BurgerIcon,
  ArrowRight,
  SuccessStepIcon,
  LogoSmall,
  WarningIcon,
  RejectStepIcon,
  PlusIcon,
  AgtiToken,
  ArrowRelation,
  Fire,
  Info,
  Circles,
  InfoOutlined,
};
