import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const YouTube: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 30 22"
    sx={{
      width: 30,
      height: 22,
      ...sx,
    }}
  >
    <path
      d="M29.2473 3.87498C29.0781 3.24742 28.7476 2.67511 28.2885 2.215C27.8294 1.75489 27.2579 1.42304 26.6307 1.25247C24.3052 0.613922 15.0017 0.603527 15.0017 0.603527C15.0017 0.603527 5.69961 0.593132 3.37262 1.20347C2.74584 1.38188 2.17544 1.71843 1.7162 2.1808C1.25695 2.64316 0.924276 3.21582 0.750107 3.8438C0.136802 6.16931 0.130862 10.9926 0.130862 10.9926C0.130862 10.9926 0.124922 15.8396 0.733772 18.1414C1.07532 19.414 2.0777 20.4194 3.35183 20.7624C5.7011 21.401 14.9794 21.4113 14.9794 21.4113C14.9794 21.4113 24.2829 21.4217 26.6084 20.8129C27.2358 20.6426 27.8079 20.3115 28.2681 19.8524C28.7283 19.3932 29.0607 18.8219 29.2324 18.1948C29.8472 15.8708 29.8517 11.049 29.8517 11.049C29.8517 11.049 29.8814 6.20049 29.2473 3.87498V3.87498ZM12.0257 15.461L12.0331 6.55095L19.7655 11.0134L12.0257 15.461V15.461Z"
      fill="#1A1A1A"
    />
  </SvgIcon>
);
