import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material';
import { COLOR_GRAY_EXTRALIGHT } from 'theme/colors';

export const getMuiCssBaselineOverrides = (theme: Theme): ComponentsOverrides['MuiCssBaseline'] => ({
  '*::-webkit-scrollbar': {
    width: 6,
  },
  '*::-webkit-scrollbar-thumb': {
    backgroundColor: COLOR_GRAY_EXTRALIGHT,
    borderRadius: 20,
  },
  html: {
    WebkitFontSmoothing: 'antialiased', // Antialiasing.
    MozOsxFontSmoothing: 'grayscale', // Antialiasing.
    boxSizing: 'border-box',
  },
  body: {
    overflowX: 'hidden',
    margin: theme.spacing(0),
    overflowY: 'overlay',
  },
});

export const getMuiCssBaselineDefaultProps = (): ComponentsProps['MuiCssBaseline'] => ({});
