import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const CheckIcon: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 30 30"
    sx={{
      ...sx,
      width: '30px',
      height: '30px',
    }}
  >
    <path
      d="M0 8.05556C0 3.6066 3.6066 0 8.05556 0H21.9444C26.3934 0 30 3.6066 30 8.05556V21.9444C30 26.3934 26.3934 30 21.9444 30H8.05556C3.6066 30 0 26.3934 0 21.9444V8.05556Z"
      fill="#B2D795"
    />
    <g clipPath="url(#clip0_268_508)">
      <g filter="url(#filter0_d_268_508)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.4223 8.95485C20.9804 8.33481 21.9354 8.28454 22.5554 8.84258C23.1755 9.40062 23.2258 10.3556 22.6677 10.9757L13.6052 21.0451C13.0263 21.6883 12.0264 21.7146 11.4145 21.1027L7.38673 17.075C6.79687 16.4851 6.79687 15.5288 7.38673 14.9389C7.97658 14.349 8.93292 14.349 9.52278 14.9389L12.4248 17.841L20.4223 8.95485Z"
          fill="white"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_268_508"
        x="6.94434"
        y="8.45483"
        width="16.1113"
        height="13.8455"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="0.755208" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_268_508" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_268_508" result="shape" />
      </filter>
      <clipPath id="clip0_268_508">
        <rect width="16.1111" height="13.0903" fill="white" transform="translate(6.94434 8.45483)" />
      </clipPath>
    </defs>
  </SvgIcon>
);
