const APPROVE = 'APPROVE' as const;
const GET_TOKEN_BALANCE = 'GET_TOKEN_BALANCE' as const;
const GET_NATIVE_BALANCE = 'GET_NATIVE_BALANCE' as const;
const GET_RANK_ID = 'GET_RANK_ID' as const;
const UPDATE_USER_DATA = 'UPDATE_USER_DATA' as const;
const LOGIN = 'LOGIN' as const;
const SET_DAD_REFERRAL_CODE = 'SET_DAD_REFERRAL_CODE' as const;
const GET_REFERRAL_INFO = 'GET_REFERRAL_INFO' as const;
const GET_REFERRAL_REWARD_DATA = 'GET_REFERRAL_REWARD_DATA' as const;
const GET_REFERRAL_TOTAL_EARNED = 'GET_REFERRAL_TOTAL_EARNED' as const;
const CLAIM_REFERRAL_TOKENS = 'CLAIM_REFERRAL_TOKENS' as const;

export default {
  APPROVE,
  GET_TOKEN_BALANCE,
  GET_NATIVE_BALANCE,
  GET_RANK_ID,
  UPDATE_USER_DATA,
  LOGIN,
  SET_DAD_REFERRAL_CODE,
  GET_REFERRAL_INFO,
  GET_REFERRAL_REWARD_DATA,
  GET_REFERRAL_TOTAL_EARNED,
  CLAIM_REFERRAL_TOKENS,
};
