import { FC } from 'react';
import { LoaderIcon } from 'components/Icon/components';
import { SpinnerPositionProps, SpinnerSizeProps } from 'types';

import { spinnerStyleState } from './styleState';

export interface SpinnerProps {
  size?: SpinnerSizeProps;
  position?: SpinnerPositionProps;
}

export const Spinner: FC<SpinnerProps> = ({ size = 's', position = 'initial' }) => {
  return (
    <LoaderIcon
      sx={{
        ...spinnerStyleState.size[size],
        ...spinnerStyleState.position[position],
        animation: 'rotate 1s linear infinite',
        '@keyframes rotate': {
          '0%': {
            transform: 'rotate(0)',
          },
          '100%': {
            transform: 'rotate(1turn)',
          },
        },
      }}
    />
  );
};
