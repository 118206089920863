import { fork } from 'redux-saga/effects';

import claimReward from './claimReward';
import getAirdropData from './getAirdropData';
import getRegistrationDates from './getRegistrationDates';
import getRegistrationStatus from './getRegistrationStatus';
import signUp from './signUp';
import updateAirdropData from './updateAirdropData';

export default function* airdropSagas() {
  yield fork(getRegistrationDates);
  yield fork(signUp);
  yield fork(getRegistrationStatus);
  yield fork(getAirdropData);
  yield fork(claimReward);
  yield fork(updateAirdropData);
}
