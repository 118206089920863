import { createContext, FC, ReactNode, useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { IConnect, IError } from '@amfi/connect-wallet/dist/interface';
import { useShallowSelector } from 'hooks';
import { Subscription } from 'rxjs';
import { contractsConfig, ContractsNames } from 'services/WalletService/config';
import { notifyText } from 'services/WalletService/config/constants';
import { resetAirdropData } from 'store/airdrop/reducer';
import { stakingDisconnectWalletState } from 'store/staking/reducer';
import { login } from 'store/user/actions';
import { disconnectWalletState } from 'store/user/reducer';
import userSelector from 'store/user/selectors';
import { Chains, State, UserState, WalletProviders } from 'types';
import { getToastMessage } from 'utils';

import { WalletService } from '../WalletService';

interface IContextValue {
  connect: (provider: WalletProviders, chain: Chains) => Promise<void>;
  disconnect: () => void;
  addTokenToWallet: () => void;
  walletService: WalletService;
}

interface WalletConnectProps {
  children: ReactNode;
}

type IAccountInfo = IConnect | IError | { address: string };

const Web3Context = createContext({} as IContextValue);

const WalletConnectContext: FC<WalletConnectProps> = ({ children }) => {
  const subscriberRef = useRef<Subscription | null>(null);
  const WalletConnect = useMemo(() => new WalletService(), []);
  const dispatch = useDispatch();
  const {
    address,
    key: token,
    provider: WalletProvider,
    chainType,
    network,
  } = useShallowSelector<State, UserState>(userSelector.getUser);

  const disconnect = useCallback(() => {
    dispatch(disconnectWalletState());
    dispatch(stakingDisconnectWalletState());
    dispatch(resetAirdropData());
    WalletConnect.resetConnect();
    subscriberRef.current?.unsubscribe();
    subscriberRef.current = null;
    getToastMessage('info', notifyText.disconnet.info);
  }, [WalletConnect, dispatch]);

  const subscriberSuccess = useCallback(
    (res: { name: string }) => {
      if (document.visibilityState !== 'visible') {
        disconnect();
      }
      if (res.name === 'accountsChanged') {
        disconnect();
      }
    },
    [disconnect],
  );

  const subscriberError = useCallback(() => {
    WalletConnect.resetConnect();
    getToastMessage('error', 'You changed to wrong network. Please choose Binance-Smart-Chain');
    dispatch(disconnectWalletState());
  }, [WalletConnect, dispatch]);

  const connect = useCallback(
    async (provider: WalletProviders, chain: Chains) => {
      const connected = await WalletConnect.initWalletConnect(provider, chain, chainType);
      try {
        if (connected) {
          if (!subscriberRef.current) {
            subscriberRef.current = WalletConnect.eventSubscribe().subscribe(subscriberSuccess, subscriberError);
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const accountInfo: IAccountInfo = await WalletConnect.getAccount();
          const accountAddress = (accountInfo as IConnect).address;
          const currentProvider = (accountInfo as IError).type;
          if (accountAddress && currentProvider) {
            dispatch(
              login({ address: accountAddress, web3Provider: WalletConnect.Web3(), token, provider: currentProvider }),
            );
          }
        } else {
          throw Error();
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        if (!window.ethereum) {
          if (provider === WalletProviders.metamask) {
            window.open(`https://metamask.app.link/dapp/${window.location.hostname + window.location.pathname}`);
          }
          return;
        }
        if (error.code === 4) {
          getToastMessage('error', typeof error.message === 'string' ? error.message : error.message.text);
        }
      }
    },
    [WalletConnect, chainType, dispatch, subscriberError, subscriberSuccess, token],
  );

  const addTokenToWallet = useCallback(async () => {
    try {
      if (window.ethereum) {
        const tokenAddress = contractsConfig.contracts[ContractsNames.agti][chainType].address[Chains.bsc];
        const {
          symbol: tokenSymbol,
          decimals: tokenDecimals,
          img: tokenImage,
        } = contractsConfig.contracts[ContractsNames.agti][chainType];

        // wasAdded is a boolean. Like any RPC method, an error may be thrown.
        const wasAdded = await window.ethereum.request({
          method: 'wallet_watchAsset',
          params: {
            type: 'ERC20', // Initially only supports ERC20, but eventually more!
            options: {
              address: tokenAddress, // The address that the token is at.
              symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
              decimals: tokenDecimals, // The number of decimals in the token
              image: tokenImage, // A string url of the token logo
            },
          },
        });

        if (wasAdded) {
          getToastMessage('success', `${tokenSymbol?.toUpperCase()} added to your wallet`);
        } else {
          getToastMessage('error', 'Something went wrong, check your metamask wallet');
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, [chainType]);

  useEffect(() => {
    // connect user if he connected previously
    if (WalletProvider && !address.length) {
      connect(WalletProvider as WalletProviders, network);
    }
  }, []);

  return (
    <Web3Context.Provider value={{ connect, disconnect, addTokenToWallet, walletService: WalletConnect }}>
      {children}
    </Web3Context.Provider>
  );
};

const useWalletConnectorContext = () => useContext(Web3Context);

export { WalletConnectContext, useWalletConnectorContext };
