import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';
import { Copy, Modal, ModalProps } from 'components';

export interface DisconnectModalProps extends ModalProps {
  address: string;
  disconnect: () => void;
}

export const DisconnectModal: FC<DisconnectModalProps> = ({ address, disconnect, open, onClose }) => {
  const handleDisconnect = () => {
    disconnect();
    onClose();
  };
  const { t } = useTranslation();

  return (
    <Modal open={open} onClose={onClose} title={t('header.wallet')}>
      <Box sx={{ maxWidth: '302px' }}>
        <Copy copyText={address}>
          <Typography noWrap className="l">
            {address}
          </Typography>
        </Copy>
        <Button
          variant="contained"
          sx={{
            width: '100%',
            mt: 2,
          }}
          onClick={handleDisconnect}
        >
          {t('header.disconnect')}
        </Button>
      </Box>
    </Modal>
  );
};
