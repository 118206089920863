import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const MediumIcon: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 30 16"
    sx={{
      ...sx,
      width: '30px',
      height: '16px',
    }}
  >
    <path
      d="M28.0277 13.9C27.6125 13.5 26.9896 11.6 26.9896 7.99998C26.9896 4.39998 27.6125 2.39998 27.9239 2.09998C28.3391 2.49998 28.8581 4.59998 28.8581 7.99998C28.9619 11.6 28.3391 13.5 28.0277 13.9Z"
      fill="#323232"
    />
    <path
      d="M21.2795 14.6C19.411 14.6 17.8539 11.6 17.8539 8.00002C17.8539 5.50002 18.5805 3.20002 19.7224 2.10002C20.2414 1.60002 20.7605 1.40002 21.2795 1.40002C22.1099 1.40002 23.0442 2.10002 23.667 3.20002C24.3937 4.50002 24.8089 6.20002 24.8089 8.00002C24.8089 11.6 23.148 14.6 21.2795 14.6Z"
      fill="#323232"
    />
    <path
      d="M8.20072 15C4.25608 15 1.03809 11.9 1.03809 8C1.03809 6.1 1.76473 4.4 3.11421 3C4.46369 1.7 6.3322 1 8.20072 1C10.173 1 11.9377 1.7 13.2872 3.1C14.6367 4.4 15.3633 6.2 15.3633 8.1C15.4672 11.9 12.1454 15 8.20072 15Z"
      fill="#323232"
    />
  </SvgIcon>
);
