import { FC, PropsWithChildren, ReactNode } from 'react';
import { Box, Dialog, Grid, IconButton, ModalProps as MuiModalProps, Typography } from '@mui/material';
import { Close } from 'components/Icon/components';
import { COLOR_BEIGE, COLOR_BLACK } from 'theme/colors';
import { FontWeights } from 'theme/Typography';

export type ModalProps = {
  // size?: 'sm' | 'md' | 'lg';
  open: boolean;
  onClose: () => void;
  closable?: boolean;
  title?: string | ReactNode;
  sx?: MuiModalProps['sx'];
};

export const Modal: FC<PropsWithChildren<ModalProps>> = ({ open, onClose, closable = true, title, children, sx }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      transitionDuration={{
        enter: 250,
        exit: 100,
      }}
      sx={sx}
    >
      {title && (
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={(theme) => ({
            padding: theme.spacing(3, 2.5, 0),
            background: COLOR_BEIGE,
          })}
        >
          <Grid item xs={11}>
            <Typography variant="h5" fontWeight={FontWeights.fontWeightLight} color={COLOR_BLACK}>
              {title}
            </Typography>
          </Grid>
          {closable && (
            <Grid item xs={1}>
              <IconButton onClick={onClose}>
                <Close />
              </IconButton>
            </Grid>
          )}
        </Grid>
      )}

      <Box
        sx={(theme) => ({
          padding: theme.spacing(1.75, 2.5, 4),
          background: COLOR_BEIGE,
        })}
      >
        {children}
      </Box>
    </Dialog>
  );
};
