import { contractsConfig, ContractsNames } from 'services/WalletService/config';
import apiActions from 'store/api/actions';
import userSelector from 'store/user/selectors';
import { all, call, put, select, takeLatest } from 'typed-redux-saga';
import { Erc20Abi, UserState } from 'types';
import { CrowdsaleAbi } from 'types/contracts/CrowdsaleAbi';
import { AbiItem } from 'web3-utils';

import { getBuyData } from '../actions';
import actionTypes from '../actionTypes';
import { updateCrowdsaleState } from '../reducer';

export function* getBuyDataSaga({ type, payload: { web3Provider } }: ReturnType<typeof getBuyData>) {
  yield* put(apiActions.request(type));
  const { address: userAddress, network, chainType }: UserState = yield select(userSelector.getUser);

  const { address: agtitokenContractAddress, abi: tokenAbi } =
    contractsConfig.contracts[ContractsNames.agti][chainType];

  const { address: usdTtokenContractAddress } = contractsConfig.contracts[ContractsNames.usdt][chainType];

  const { address: crowdsaleContractAddress, abi: crowdsaleAbi } =
    contractsConfig.contracts[ContractsNames.crowdsale][chainType];

  const agtiTokenContract: Erc20Abi = yield new web3Provider.eth.Contract(
    tokenAbi as AbiItem[],
    agtitokenContractAddress[network],
  );

  const usdtTokenContract: Erc20Abi = yield new web3Provider.eth.Contract(
    tokenAbi as AbiItem[],
    usdTtokenContractAddress[network],
  );

  const crowdsaleContract: CrowdsaleAbi = yield new web3Provider.eth.Contract(
    crowdsaleAbi as AbiItem[],
    crowdsaleContractAddress[network],
  );

  let agtiBalance = '0';
  let usdtBalance = '0';
  try {
    const [agtiPrice, totalAgtiTokensOnCrowdsale, availableAgtiTokensOnCrowdsale]: string[] = yield* all([
      call(crowdsaleContract.methods.price(usdTtokenContractAddress[network]).call),
      call(agtiTokenContract.methods.balanceOf(crowdsaleContractAddress[network]).call),
      call(crowdsaleContract.methods.avaliableToBuy().call),
    ]);

    if (userAddress) {
      const [newAgtiBalance, newUsdtBalance]: string[] = yield* all([
        call(agtiTokenContract.methods.balanceOf(userAddress).call),
        call(usdtTokenContract.methods.balanceOf(userAddress).call),
      ]);

      agtiBalance = newAgtiBalance;
      usdtBalance = newUsdtBalance;
    }

    yield put(
      updateCrowdsaleState({
        agtiBalance,
        agtiPrice,
        usdtBalance,
        totalTokensOnCrowdSale: totalAgtiTokensOnCrowdsale,
        availableTokensOnCrowdSale: availableAgtiTokensOnCrowdsale,
      }),
    );

    yield* put(apiActions.success(type));
  } catch (err) {
    console.log(err);
    yield* put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_BUY_DATA, getBuyDataSaga);
}
