import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StakingState } from 'types/store/staking';

const initialState: StakingState = {
  litePool: null,
  hardPool: null,
  userStakes: {
    liteStake: null,
    hardStake: null,
    pastStakes: [],
  },
};

export const stakingReducer = createSlice({
  name: 'staking',
  initialState,
  reducers: {
    updateStakingState: (state: StakingState, action: PayloadAction<Partial<StakingState>>) => ({
      ...state,
      ...action.payload,
    }),
    updateUserStakesState: (state: StakingState, action: PayloadAction<Partial<StakingState['userStakes']>>) => ({
      ...state,
      userStakes: {
        ...state.userStakes,
        ...action.payload,
      },
    }),
    resetStakingData: () => ({
      ...initialState,
    }),
    stakingDisconnectWalletState: (state: StakingState) => ({
      ...state,
      userStakes: {
        ...initialState.userStakes,
      },
    }),
  },
});

export const { updateStakingState, updateUserStakesState, resetStakingData, stakingDisconnectWalletState } =
  stakingReducer.actions;

export default stakingReducer.reducer;
