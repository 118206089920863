import { GetRewardsBg, MainBgImage } from 'modules/landing/assets/img';
import { COLOR_BEIGE, COLOR_RED } from 'theme/colors';

// ===== Borders
export const BORDER_MAIN = '1px solid #323232';
export const BORDER_MAIN_BOLD = '2px solid #323232';
export const BORDER_GREEN = '1px solid #3D7C0B';
export const BORDER_GREEN_BOLD = '2px solid #3D7C0B';
export const BORDER_GRAY = '1px solid #707070';
export const BORDER_GRAY_BOLD = '2px solid #BCBCBC';
export const BORDER_RED = `1px solid ${COLOR_RED}`;

// ===== Border-Radius

export const BORDER_RADIUS_DEFAULT = '16px';
export const BORDER_RADIUS_SMALL = '8px';
export const BORDER_RADIUS_MEDIUM = '12px';
export const BORDER_RADIUS_LARGE = '24px';

// ===== Transitions
export const TRANSITION_DEFAULT_TIME = 'all 0.25s ease-in-out';

export const HOME_IMAGE_BG = `url(${MainBgImage}) ${COLOR_BEIGE} center/100% no-repeat`;
export const GET_REWARDS_IMAGE_BG = `url(${GetRewardsBg}) ${COLOR_BEIGE} 100% no-repeat`;

export const BOX_SHADOW_DEFAULT = '0px 2px 23px -2px rgb(0 0 0 / 3%), 0px 10px 10px -5px rgb(0 0 0 / 4%)';
