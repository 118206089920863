import { fork } from 'redux-saga/effects';
/* PLOP_INJECT_IMPORT_SAGA */
import airdropSagas from 'store/airdrop/sagas';
import crowdsaleSaga from 'store/crowdsale/sagas';
import stakingSagas from 'store/staking/sagas';
import userSaga from 'store/user/sagas';

export default function* rootSaga() {
  yield fork(userSaga);
  yield fork(crowdsaleSaga);
  yield fork(airdropSagas);
  yield fork(stakingSagas);
}
