import { FC } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { Modal, ModalProps } from 'components';

import { socialData } from './ShareSocialModal.helper';

export interface ShareSocialModalProps extends ModalProps {
  onShareClick: (name: string) => void;
}

export const ShareSocialModal: FC<ShareSocialModalProps> = ({ onShareClick, onClose, open }) => {
  return (
    <Modal open={open} onClose={onClose} sx={{ '&.MuiBox-root': { px: '28px', py: '26px' } }}>
      <Stack sx={{ width: '200px', minHeight: '100px' }} spacing={3} alignItems="flex-start">
        {socialData.map(({ name, icon }) => (
          <Button variant="text" key={name} onClick={() => onShareClick(name)} sx={{ textTransform: 'unset', p: 0 }}>
            <Stack spacing={2} direction="row" justifyContent="center" alignItems="center">
              {icon}
              <Typography fontSize="18px" lineHeight="34px">
                {name}
              </Typography>
            </Stack>
          </Button>
        ))}
      </Stack>
    </Modal>
  );
};
