import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const LogoSmall: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    width={25}
    height={30}
    viewBox="0 0 25 30"
    sx={{
      path: {
        fill: '#002D74',
      },
      ...sx,
    }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.14848 3.6716L0.109329 7.33733L0.0545604 14.9541L0 22.5711L6.15764 26.2882C9.54436 28.3326 12.4271 30.0028 12.5639 30C12.7005 29.997 15.53 28.3457 18.8515 26.3304L24.8907 22.6659L24.9454 15.047L25 7.42802L18.8424 3.71097C15.4556 1.6665 12.5729 -0.00347414 12.4361 5.42769e-06C12.2995 0.00326753 9.47001 1.65563 6.14848 3.6716ZM17.0046 7.11247L21.2464 9.66408L21.2428 14.9959L21.2391 20.3277L17.0666 22.8286C14.7718 24.2041 12.7597 25.3611 12.5952 25.3993C12.4307 25.4378 10.3746 24.3124 8.02645 22.8984L3.75677 20.3277L3.75948 14.9996L3.76218 9.67147L7.9748 7.12899C10.292 5.73064 12.317 4.58086 12.4752 4.57368C12.6333 4.56672 14.6716 5.70911 17.0046 7.11247ZM9.43045 9.74607L6.67305 11.4113L6.6718 14.9996L6.67055 18.5879L9.41795 20.2189C10.929 21.116 12.2722 21.8785 12.4027 21.9135C12.5789 21.9604 17.8484 18.9765 18.2982 18.5748C18.4094 18.4757 16.1762 17.1786 15.89 17.1763C15.7501 17.1752 14.9317 17.609 14.0712 18.1401L12.5069 19.1061L10.8374 18.0888L9.16806 17.0714V14.9996V12.9277L10.8374 11.9104L12.5069 10.893L14.0712 11.859C14.9317 12.3901 15.7501 12.824 15.89 12.8229C16.1762 12.8205 18.4094 11.5235 18.2982 11.4243C17.923 11.0892 12.6118 8.04521 12.429 8.06065C12.2961 8.07152 10.9469 8.83007 9.43045 9.74607Z"
      fill="currentColor"
    />
  </SvgIcon>
);
