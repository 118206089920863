import { airdropAbi } from 'services/WalletService/config/abi';
import { notifyText } from 'services/WalletService/config/constants';
import apiActions from 'store/api/actions';
import store from 'store/configureStore';
import { setActiveModal } from 'store/modals/reducer';
import userSelector from 'store/user/selectors';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import { Modals, UserState } from 'types';
import { AirdropAbi } from 'types/contracts/AirdropAbi';
import { estimateGasForMethod, getToastMessage } from 'utils';
import { AbiItem } from 'web3-utils';

import { claimReward } from '../actions';
import actionTypes from '../actionTypes';

import { getAirdropDataSaga } from './getAirdropData';
import { updateAirdropDataSaga } from './updateAirdropData';

const alreadyClaimedRegExp = /"message":.*execution reverted: Already claimed.*/gi;

export function* claimRewardSaga({
  type,
  payload: { web3Provider, rewardTokenAmount, airdropNumber, id, proof, airdropAddress },
}: ReturnType<typeof claimReward>): Generator<unknown, void, any> {
  yield* put(apiActions.request(type));

  const { address: userAddress }: UserState = yield select(userSelector.getUser);

  const updateAirdropDataArgs = {
    id,
    airdrop_number: airdropNumber,
    address: userAddress,
  };

  const airdropContract: AirdropAbi = yield new web3Provider.eth.Contract(airdropAbi as AbiItem[], airdropAddress);

  try {
    yield* put(
      setActiveModal({
        activeModal: Modals.SendPending,
        open: true,
      }),
    );

    const { estimatedGas, gasPrice } = yield* call(estimateGasForMethod, {
      web3Provider,
      contract: airdropContract,
      method: 'claim',
      estimateGasArgs: { from: userAddress },
      methodArgs: [airdropNumber, rewardTokenAmount, proof],
    });

    const { transactionHash } = yield call(
      airdropContract.methods.claim(airdropNumber, rewardTokenAmount, proof).send,
      {
        from: userAddress,
        gasPrice: Number(gasPrice),
        gas: estimatedGas,
      },
    );

    yield* put(
      setActiveModal({
        activeModal: Modals.SendSuccess,
        open: true,
        txHash: transactionHash,
      }),
    );

    yield call(updateAirdropDataSaga, {
      type: actionTypes.UPDATE_AIRDROP_DATA,
      payload: updateAirdropDataArgs,
    });

    yield call(getAirdropDataSaga, {
      type: actionTypes.GET_AIRDROP_DATA,
    });

    yield* put(apiActions.success(type));
  } catch (err: any) {
    yield* put(apiActions.error(type, err));

    console.error(err);

    if (!!err?.message && alreadyClaimedRegExp.test(err?.message)) {
      // If user claimed previously, but didnt send update airdrop request -> update on the next failed claim request
      yield call(updateAirdropDataSaga, {
        type: actionTypes.UPDATE_AIRDROP_DATA,
        payload: updateAirdropDataArgs,
      });

      yield call(getAirdropDataSaga, {
        type: actionTypes.GET_AIRDROP_DATA,
      });

      getToastMessage('info', notifyText.claim.info);
    }

    yield* put(
      setActiveModal({
        activeModal: Modals.SendRejected,
        open: true,
        repeatHandler: () =>
          store.store.dispatch({
            type,
            payload: {
              web3Provider,
              airdropNumber,
              rewardTokenAmount,
              proof,
            },
          }),
      }),
    );
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.CLAIM_REWARD, claimRewardSaga);
}
