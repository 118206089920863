import { updateAirdropData } from 'store/airdrop/actions';
import actionTypes from 'store/airdrop/actionTypes';
import { error, request, success } from 'store/api/actions';
import { baseApi } from 'store/api/apiRequestBuilder';
import { call, put, takeLatest } from 'typed-redux-saga';

export function* updateAirdropDataSaga({ type, payload }: ReturnType<typeof updateAirdropData>) {
  yield put(request(type));
  try {
    yield call(baseApi.updateAirdropData, { ...payload });

    yield put(success(type));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    yield put(error(type));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.UPDATE_AIRDROP_DATA, updateAirdropDataSaga);
}
