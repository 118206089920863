import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CrowdsaleState } from 'types';

const initialState: CrowdsaleState = {
  agtiPrice: '0',
  agtiBalance: '0',
  usdtBalance: '0',
  totalTokensOnCrowdSale: '0',
  availableTokensOnCrowdSale: '0',
  vestingPeriodBuys: [],
  currentVestingPeriod: '0',
};

export const crowdsaleReducer = createSlice({
  name: 'crowdsale',
  initialState,
  reducers: {
    updateCrowdsaleState: (state: CrowdsaleState, action: PayloadAction<Partial<CrowdsaleState>>) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const { updateCrowdsaleState } = crowdsaleReducer.actions;

export default crowdsaleReducer.reducer;
