import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SevlushIcon: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 32 32"
    sx={{
      ...sx,
      width: '32px',
      height: '32px',
    }}
  >
    <path
      d="M16 31C24.2843 31 31 24.2843 31 16C31 7.71573 24.2843 1 16 1C7.71573 1 1 7.71573 1 16C1 24.2843 7.71573 31 16 31Z"
      stroke="#323232"
      strokeWidth="2"
      strokeMiterlimit="10"
      fill="transparent"
    />
    <path d="M1.85938 11H30.1406" stroke="#323232" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M1.85938 21H30.1406" stroke="#323232" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M16 30.5937C19.4518 30.5937 22.25 24.0599 22.25 16C22.25 7.94009 19.4518 1.40625 16 1.40625C12.5482 1.40625 9.75 7.94009 9.75 16C9.75 24.0599 12.5482 30.5937 16 30.5937Z"
      stroke="#323232"
      strokeWidth="2"
      strokeMiterlimit="10"
      fill="transparent"
    />
  </SvgIcon>
);
