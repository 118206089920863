import { ComponentsProps } from '@mui/material';
import { TypographyOptions } from '@mui/material/styles/createTypography';
import { COLOR_BLACK, COLOR_GRAY, COLOR_GREEN, COLOR_WHITE } from 'theme/colors';

import { COLOR_BEIGE, COLOR_BLACK_ALPHA_53, COLOR_GRAY_SEMILIGHT } from '../colors/colors.constant';

export enum FontWeights {
  fontWeightLight = 300,
  fontWeightRegular = 400,
  fontWeightMedium = 500,
  fontWeightSemiBold = 600,
  fontWeightBold = 700,
}

export enum FontFamilies {
  primary = '"Helvetica Neue", sans-serif',
  secondary = '"Inter", sans-serif',
}

export const fontVariations = {
  '&.font-primary': {
    fontFamily: FontFamilies.primary,
  },
  '&.font-secondary': {
    fontFamily: FontFamilies.secondary,
  },
};

const weightVariations = {
  '&.light': {
    fontWeight: FontWeights.fontWeightLight,
  },
  '&.regular': {
    fontWeight: FontWeights.fontWeightRegular,
  },
  '&.medium': {
    fontWeight: FontWeights.fontWeightMedium,
  },
  '&.semi-bold': {
    fontWeight: FontWeights.fontWeightSemiBold,
  },
  '&.bold': {
    fontWeight: FontWeights.fontWeightBold,
  },
};

export const colorVariations = {
  '&.white': {
    color: COLOR_WHITE,
  },
  '&.green': {
    color: COLOR_GREEN,
  },
  '&.black': {
    color: COLOR_BLACK,
  },
  '&.gray': {
    color: COLOR_GRAY,
  },
  '&.lightGray': {
    color: COLOR_GRAY_SEMILIGHT,
  },
  '&.beige': {
    color: COLOR_BEIGE,
  },
};

export const styleVariations = {
  '&.center': {
    textAlign: 'center',
  },
  '&.capitalize': {
    textTransform: 'capitalize',
  },
  '&.uppercase': {
    textTransform: 'uppercase',
  },
  '&.line-through': {
    textDecoration: 'line-through',
  },
  '&.nowrap': {
    whiteSpace: 'nowrap',
  },
};

export const getTypographyOptions = (): TypographyOptions => ({
  fontFamily: FontFamilies.primary,

  allVariants: {
    color: COLOR_BLACK,
    fontWeight: FontWeights.fontWeightRegular,
    textAlign: 'left',
    fontStyle: 'normal',
    ...colorVariations,
    ...weightVariations,
    ...styleVariations,
    ...fontVariations,
  },

  h1: {
    fontSize: '142px',
    lineHeight: '120px',
    fontWeight: FontWeights.fontWeightLight,
  },

  h2: {
    fontSize: '48px',
    lineHeight: '58px',
    fontWeight: FontWeights.fontWeightLight,
  },

  h3: {
    fontSize: '36px',
    lineHeight: '42px',
    fontWeight: FontWeights.fontWeightLight,
  },

  h4: {
    fontSize: '30px',
    lineHeight: '45px',
    fontWeight: FontWeights.fontWeightRegular,
  },

  h5: {
    fontSize: '23px',
    lineHeight: '29px',
    fontWeight: FontWeights.fontWeightRegular,
  },

  body1: {
    fontSize: '17px',
    lineHeight: '24px',
    fontWeight: FontWeights.fontWeightRegular,
    '&.s': {
      fontSize: '17px',
      lineHeight: '24px',
    },
    '&.xs': {
      fontSize: '18px',
      lineHeight: '26px',
    },
    '&.m': {
      fontSize: '20px',
      lineHeight: '25px',
    },
    '&.l': {
      fontSize: '24px',
      lineHeight: '32px',
    },
    '&.xl': {
      fontSize: '32px',
      lineHeight: '42px',
    },
  },

  body2: {
    fontSize: '16px',
    lineHeight: '26px',
    fontWeight: FontWeights.fontWeightMedium,
  },

  subtitle1: {
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: FontWeights.fontWeightMedium,
    fontFamily: FontFamilies.secondary,
  },

  subtitle2: {
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: FontWeights.fontWeightMedium,
    color: COLOR_BLACK_ALPHA_53,
  },
});

export const getMuiTextFieldDefaultProps = (): ComponentsProps['MuiTypography'] => ({
  variant: 'body1',
});
