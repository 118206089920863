import { getRegistrationDates as getRegDates } from 'store/airdrop/actions';
import actionTypes from 'store/airdrop/actionTypes';
import { error, request, success } from 'store/api/actions';
import { baseApi } from 'store/api/apiRequestBuilder';
import { call, put, takeLatest } from 'typed-redux-saga';
import { AirdropDates } from 'types';
import { camelize } from 'utils';

import { updateAirdropState } from '../reducer';

export function* getRegistrationDatesSaga({ type }: ReturnType<typeof getRegDates>) {
  yield put(request(type));
  try {
    const { data } = yield call(baseApi.getRegistrationDates);

    const camelizedRegistrationData = camelize(data);

    yield put(
      updateAirdropState({
        registrationDates: camelizedRegistrationData as AirdropDates,
      }),
    );

    yield put(success(type));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    yield put(error(type));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_REGISTRATION_DATES, getRegistrationDatesSaga);
}
