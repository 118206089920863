import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const RejectStepIcon: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 101 100"
    sx={{
      width: 101,
      height: 100,
      path: {
        fill: '#B2D795',
      },
      ...sx,
    }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M50.5 100C78.1142 100 100.5 77.6142 100.5 50C100.5 22.3858 78.1142 0 50.5 0C22.8858 0 0.5 22.3858 0.5 50C0.5 77.6142 22.8858 100 50.5 100ZM43.2425 22.1592H57.7556L56.4283 63.1593H44.5698L43.2425 22.1592ZM58.0153 74.5851C57.9576 78.7399 54.4663 82.0868 50.5135 82.0868C46.3587 82.0868 42.954 78.7399 43.0117 74.5851C42.954 70.4879 46.3587 67.1699 50.5135 67.1699C54.4663 67.1699 57.9576 70.4879 58.0153 74.5851Z"
      fill="currentColor"
    />
  </SvgIcon>
);
