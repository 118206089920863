import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Close: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 14 14"
    sx={{
      ...sx,
      width: '14px',
      height: '14px',
    }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.64978 7.00015L13.6582 1.99175C14.1143 1.53559 14.1143 0.798275 13.6582 0.342118C13.202 -0.114039 12.4647 -0.114039 12.0085 0.342118L7.00014 5.35051L1.99175 0.342118C1.53559 -0.114039 0.798275 -0.114039 0.342118 0.342118C-0.114039 0.798275 -0.114039 1.53559 0.342118 1.99175L5.35051 7.00015L0.342118 12.0085C-0.114039 12.4647 -0.114039 13.202 0.342118 13.6582C0.569613 13.8857 0.868273 14 1.16693 14C1.46559 14 1.76425 13.8857 1.99175 13.6582L7.00014 8.64978L12.0085 13.6582C12.236 13.8857 12.5347 14 12.8334 14C13.132 14 13.4307 13.8857 13.6582 13.6582C14.1143 13.202 14.1143 12.4647 13.6582 12.0085L8.64978 7.00015Z"
      fill="#323232"
    />
  </SvgIcon>
);
