import { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { Box, Button, Container, Grid, Stack, styled, Typography, useTheme } from '@mui/material';
import { routes } from 'appConstants';
import { MainLogo, WalletIcon } from 'components/Icon/components';
import { useModal, useShallowSelector, useWindowState } from 'hooks';
import { Arrow } from 'modules/landing/assets';
import { Account, ConnectWalletModal, DisconnectModal, LanguageModal, Menu } from 'modules/layout/containers';
import { useGetScrollValue } from 'modules/layout/hooks/useGetScrollValue';
import { formatRoutesToArr } from 'modules/router/utils';
import { useWalletConnectorContext } from 'services';
import userSelector from 'store/user/selectors';
import { COLOR_BEIGE, COLOR_BLACK, COLOR_GREEN } from 'theme/colors';
import { BOX_SHADOW_DEFAULT, TRANSITION_DEFAULT_TIME } from 'theme/variables';
import { State, UserState } from 'types';

import { HeaderModals } from './Header.types';

type LinkTypographyProps = {
  isActive?: boolean;
};

const LinkTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<LinkTypographyProps>(({ isActive }) => ({
  fontSize: '22px',
  lineHeight: '23px',
  whiteSpace: 'pre-line',
  textAlign: 'center',
  color: isActive ? COLOR_GREEN : COLOR_BLACK,
  textDecoration: isActive ? 'underline' : 'none',
  '&:hover': {
    color: COLOR_GREEN,
  },
}));

const stylesApplyingPoint = 150;

export const Header: FC = () => {
  const theme = useTheme();
  const { address } = useShallowSelector<State, UserState>(userSelector.getUser);
  const [scrollToTopValue] = useGetScrollValue();
  const { width } = useWindowState();
  const popoverRef = useRef(null);

  const [isLanguageModalVisible, onOpenLanguageModal, onCloseLanguageModal] = useModal(false);

  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { hash, pathname } = useLocation();

  const [openModalType, setOpenModalType] = useState(HeaderModals.none);

  const handleOpenModal = (modalType: HeaderModals) => () => setOpenModalType(modalType);

  const { connect, disconnect } = useWalletConnectorContext();

  return (
    <Box
      sx={{
        px: { md: 3 },
        pt: scrollToTopValue <= stylesApplyingPoint ? 3.25 : 0.4,
        position: 'fixed',
        top: '0',
        left: '0',
        right: '0',
        zIndex: '2',
        background: COLOR_BEIGE,
        transition: TRANSITION_DEFAULT_TIME,
        boxShadow: scrollToTopValue > stylesApplyingPoint ? BOX_SHADOW_DEFAULT : 'none',
      }}
    >
      <Container>
        <Grid
          container
          direction="row"
          sx={{ position: 'relative' }}
          justifyContent="space-between"
          alignItems="center"
        >
          {width < theme.breakpoints.values.lg && (
            <>
              <Menu />
              <Link to="/">
                <MainLogo />
              </Link>
            </>
          )}
          <Grid alignItems="center" container item justifyContent="space-between" lg={12}>
            {formatRoutesToArr(routes).map(({ root, title, isProtected }, id) =>
              !isProtected ? (
                // eslint-disable-next-line react/no-array-index-key
                <Grid key={id} item sx={{ display: { xs: 'none', lg: 'flex' } }}>
                  <Link to={root}>
                    <LinkTypography isActive={pathname + hash === root}>
                      {root === routes.home.root ? title : t(`header.navLinks.${title}`)}
                    </LinkTypography>
                  </Link>
                </Grid>
              ) : (
                // eslint-disable-next-line react/no-array-index-key
                <Grid key={id} item sx={{ display: { xs: 'none', lg: 'flex' } }}>
                  <Button
                    disabled={!address}
                    sx={{ textTransform: 'capitalize' }}
                    component={Link}
                    to={root}
                    variant="text"
                  >
                    <LinkTypography isActive={pathname + hash === root}>{t(`header.navLinks.${title}`)}</LinkTypography>
                  </Button>
                </Grid>
              ),
            )}
            <Stack direction="row" alignItems="center" spacing={3}>
              {width >= theme.breakpoints.values.lg && (
                <Button
                  variant="outlined"
                  color="secondary"
                  sx={{
                    fontSize: '16px',
                    p: theme.spacing(0, 1),
                    minWidth: 0,
                    width: '42px',
                    textTransform: 'lowercase',
                    height: '42px',
                  }}
                  ref={popoverRef}
                  onClick={onOpenLanguageModal}
                >
                  {language}
                </Button>
              )}
              {address.length ? (
                <Account width={width} address={address} onClick={handleOpenModal(HeaderModals.disconnect)} />
              ) : (
                <>
                  <Button
                    size="medium"
                    sx={{
                      px: { xs: 1, sm: 1, lg: 2 },
                      minWidth: { xs: '56px', lg: '230px' },
                      height: '56px',
                      textTransform: 'none',
                    }}
                    onClick={handleOpenModal(HeaderModals.connect)}
                    startIcon={width < theme.breakpoints.values.lg && <WalletIcon />}
                    endIcon={width >= theme.breakpoints.values.lg && <WalletIcon />}
                  >
                    {width >= theme.breakpoints.values.lg && t('header.connectWallet')}
                  </Button>
                  <Box
                    pt={3.5}
                    component="img"
                    sx={{
                      display: {
                        xs: 'none',
                        lg: scrollToTopValue > 50 ? 'none' : 'block',
                      },
                      position: 'absolute',
                      right: 0,
                      transform: 'translate(50%, 45%)',
                      top: 0,
                    }}
                    src={Arrow}
                    alt="arrow"
                  />
                </>
              )}
            </Stack>
          </Grid>
        </Grid>
      </Container>
      <ConnectWalletModal
        onClose={handleOpenModal(HeaderModals.none)}
        onConnectWallet={connect}
        open={openModalType === HeaderModals.connect}
      />
      <DisconnectModal
        open={openModalType === HeaderModals.disconnect}
        address={address}
        onClose={handleOpenModal(HeaderModals.none)}
        disconnect={disconnect}
      />
      {isLanguageModalVisible && (
        <LanguageModal anchorEl={popoverRef} open={isLanguageModalVisible} onClose={onCloseLanguageModal} />
      )}
    </Box>
  );
};
