import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const WalletIcon: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 25 22"
    sx={{
      ...sx,
      width: '24px',
      height: '22px',
    }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 2C2.73478 2 2.48043 2.10536 2.29289 2.29289C2.10536 2.48043 2 2.73478 2 3C2 3.26522 2.10536 3.51957 2.29289 3.70711C2.48043 3.89464 2.73478 4 3 4H23C23.5304 4 24.0391 4.21071 24.4142 4.58579C24.7893 4.96086 25 5.46957 25 6V20C25 20.5304 24.7893 21.0391 24.4142 21.4142C24.0391 21.7893 23.5304 22 23 22H3C2.20435 22 1.44129 21.6839 0.87868 21.1213C0.31607 20.5587 0 19.7957 0 19V3C0 2.20435 0.31607 1.44129 0.87868 0.87868C1.44129 0.31607 2.20435 0 3 0H20C20.5523 0 21 0.447715 21 1C21 1.55228 20.5523 2 20 2H3ZM2 5.82843V19C2 19.2652 2.10536 19.5196 2.29289 19.7071C2.48043 19.8946 2.73478 20 3 20H23V6H3C2.65606 6 2.3182 5.94094 2 5.82843Z"
      fill="#323232"
    />
    <path
      d="M18.5 14.5C19.3284 14.5 20 13.8284 20 13C20 12.1716 19.3284 11.5 18.5 11.5C17.6716 11.5 17 12.1716 17 13C17 13.8284 17.6716 14.5 18.5 14.5Z"
      fill="#323232"
    />
  </SvgIcon>
);
