import { fork } from 'redux-saga/effects';

import approve from './approve';
import claimReferralTokens from './claimReferralTokens';
import getNativeBalance from './getNativeBalance';
import getReferralInfo from './getReferralInfo';
import getReferralRewardData from './getReferralRewardData';
import getReferralTotalEarnedSaga from './getReferralTotalEarned';
import getTokenBalance from './getTokenBalance';
import login from './login';
import setDadReferralCode from './setDadReferralCode';
import updateUserData from './updateUserData';

export default function* userSagas() {
  yield fork(approve);
  yield fork(getTokenBalance);
  yield fork(getNativeBalance);
  yield fork(updateUserData);
  yield fork(login);
  yield fork(setDadReferralCode);
  yield fork(getReferralInfo);
  yield fork(getReferralRewardData);
  yield fork(getReferralTotalEarnedSaga);
  yield fork(claimReferralTokens);
}
