import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Support: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 34 34"
    sx={{
      width: 34,
      height: 34,
      ...sx,
    }}
  >
    <rect width="34" height="34" rx="17" fill="#323232" />
    <path
      d="M26.0037 17C26.0037 21.9726 21.9726 26.0037 17 26.0037"
      stroke="#B2D795"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.99658 17.0001C7.99658 12.0275 12.0277 7.99634 17.0003 7.99634"
      stroke="#B2D795"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.99658 17.0002C7.99672 18.0549 8.1843 19.1011 8.55057 20.0901C8.71808 20.5494 9.19035 20.823 9.67214 20.7398L10.6587 20.5658C11.4561 20.4252 11.9982 19.6773 11.8837 18.8758L11.5868 16.7972C11.5293 16.3948 11.311 16.0329 10.9819 15.7944C10.6528 15.5558 10.2409 15.461 9.84064 15.5316L8.07932 15.8422"
      stroke="#B2D795"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.0037 17.0001C26.0037 12.0275 21.9726 7.99634 17 7.99634"
      stroke="#B2D795"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.0039 17.0002C26.0038 18.0549 25.8162 19.1011 25.4499 20.0901C25.2824 20.5494 24.8102 20.823 24.3284 20.7398L23.3418 20.5658C22.5444 20.4252 22.0023 19.6773 22.1168 18.8758L22.4138 16.7972C22.4713 16.3948 22.6895 16.0329 23.0186 15.7944C23.3478 15.5558 23.7596 15.461 24.1599 15.5316L25.9212 15.8422"
      stroke="#B2D795"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.4934 17.4913C18.4904 17.4913 18.4876 17.4931 18.4865 17.4959C18.4853 17.4987 18.4859 17.5019 18.4881 17.504C18.4902 17.5062 18.4934 17.5068 18.4962 17.5057C18.499 17.5045 18.5008 17.5018 18.5008 17.4987C18.501 17.4967 18.5002 17.4948 18.4988 17.4933C18.4974 17.4919 18.4954 17.4912 18.4934 17.4913"
      stroke="#B2D795"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.507 17.4942C15.504 17.4942 15.5013 17.496 15.5001 17.4988C15.499 17.5016 15.4996 17.5048 15.5018 17.507C15.5039 17.5091 15.5071 17.5097 15.5099 17.5086C15.5127 17.5074 15.5145 17.5047 15.5145 17.5017C15.5146 17.4997 15.5139 17.4977 15.5125 17.4962C15.511 17.4948 15.5091 17.4941 15.507 17.4942"
      stroke="#B2D795"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
